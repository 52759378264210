import { Box, Button, FormControl, FormLabel, Input, Link, Typography } from '@mui/joy'
import FacebookLogin from './FacebookLogin'
import GoogleLogin from './GoogleLogin'
import AppleLogin from './AppleLogin'
import { colors, fontSizes } from '../layouts/Theme'
import { useContext, useEffect, useState } from 'react'
import { extractJobFromSignUp, usingFBBrowser } from '../utils/general-utils'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import { Link as NavLink, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { CREATE_JOB } from '../graphql/job-queries'
import { BarLoader } from 'react-spinners'
import useCsrfToken from '../hooks/use-csrf-token'

export default function CustomerLoginOptions({ loginsOnly = false, signUpFirst = false }: { loginsOnly?: boolean, signUpFirst?: boolean }) {
  const csrfToken = useCsrfToken()
  const [loading, setLoading] = useState(false)
  const [emailSelected, setEmailSelected] = useState(signUpFirst ? false : true)
  const [registerSelected, setRegisterSelected] = useState(signUpFirst)
  const [fbBrowserDetected, setFbBrowserDetected] = useState(false)
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('hm:phoneNumber') || '')
  const [password, setPassword] = useState('')
  const { showAlert } = useContext(LayoutContext)
  const navigate = useNavigate()
  const source = localStorage.getItem('hm:source')
  const referralCode = localStorage.getItem('hm:referralCode')

  useEffect(() => {
    if (usingFBBrowser()) {
      setFbBrowserDetected(true)
    }
  }, [])

  const [createJob] = useMutation(CREATE_JOB)

  async function handleLogIn(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setLoading(true)
    const response = await fetch('/api/v1/customer_log_in', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken!
      },
      body: JSON.stringify({ email, password })
    })

    const json = await response.json()

    if (response.ok) {
      window.location.href = json.redirect
    } else {
      setLoading(false)
      showAlert(json.message, 'danger')
    }
  }

  async function handleRegistration(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    setLoading(true)
    const response = await fetch('/api/v1/customer_register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken!
      },
      body: JSON.stringify({ full_name: fullName, phone_number: phoneNumber, email, password, source, referral_code: referralCode })
    })

    const json = await response.json()

    if (response.ok) {
      const jobAttributes = extractJobFromSignUp()
      if (jobAttributes) {
        localStorage.removeItem('hm:newJob')
        createJob({
          variables: { attributes: { ...jobAttributes, customerId: json.customer_id } },
          onCompleted: () => {
            navigate(json.redirect)
          },
        })
      } else {
        navigate('/customer/get-estimates')
      }
    } else {
      setLoading(false)
      showAlert(json.message, 'danger')
    }
  }

  return (
    <Box sx={{ p: { xs: '0px 15px 20px', md: '5px 40px 20px' } }}>
      {!loginsOnly &&
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, mt: 1 }}>
            <Typography level="h1" sx={{ fontWeight: 500 }}>Client Login</Typography>
          </Box>
          <Typography textAlign="center" sx={{ lineHeight: '1.2rem', fontSize: fontSizes.regular, mb: 3 }}>
            Ang Client Login ay para sa mga clients na naghahanap ng contractors at service providers
          </Typography>
        </>
      }
      {csrfToken ? <Box>
        {emailSelected &&
          <Box sx={{ pb: 1, mb: 3, borderBottom: '1px solid #EEE' }}>
            <form onSubmit={handleLogIn}>
              <FormControl sx={{ mt: 2, mb: 1 }}>
                <FormLabel>Email Address</FormLabel>
                <Input required onChange={({ target: { value }}) => setEmail(value)} value={email} autoFocus type="email" placeholder="Your email address" />
              </FormControl>
              <FormControl sx={{ mt: 1, mb: 1 }}>
                <FormLabel>Password</FormLabel>
                <Input required onChange={({ target: { value }}) => setPassword(value)} value={password} type="password" placeholder="Your password" />
                <Link component={NavLink} to="/customer_forgot_password" sx={{ fontSize: fontSizes.xs, color: colors.gray3, position: 'absolute', right: '12px', top: '37px' }}>Forgot?</Link>
              </FormControl>
              <FormControl sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button sx={{ mt: 1 }} type="submit" disabled={loading}>Log In</Button>
                <Button sx={{ mt: 1 }} variant="plain" color="neutral" onClick={() => { setEmailSelected(false); setRegisterSelected(true) }}>Wala ka pang account?</Button>
              </FormControl>
            </form>
          </Box>}
        {registerSelected &&
          <Box sx={{ pb: 1, mb: 3, borderBottom: '1px solid #EEE' }}>
            <form onSubmit={handleRegistration}>
              <FormControl sx={{ mt: 2, mb: 1 }}>
                <FormLabel>Full Name</FormLabel>
                <Input required onChange={({ target: { value }}) => setFullName(value)} value={fullName} autoFocus placeholder="Your name" />
              </FormControl>
              <FormControl sx={{ mt: 2, mb: 1 }}>
                <FormLabel>Email Address</FormLabel>
                <Input required onChange={({ target: { value }}) => setEmail(value)} value={email} type="email" placeholder="Your email address" />
              </FormControl>
              <FormControl sx={{ mt: 2, mb: 1 }}>
                <FormLabel>Phone Number (Optional)</FormLabel>
                <Input onChange={({ target: { value }}) => setPhoneNumber(value)} value={phoneNumber} placeholder="Mobile or landline number" />
              </FormControl>
              <FormControl sx={{ mt: 2, mb: 1 }}>
                <FormLabel>Password</FormLabel>
                <Input required onChange={({ target: { value }}) => setPassword(value)} value={password} type="password" placeholder="Your password" />
              </FormControl>
              <FormControl sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button sx={{ mt: 2 }} type="submit" disabled={loading}>Register</Button>
                <Button sx={{ mt: 1 }} variant="plain" color="neutral" onClick={() => { setEmailSelected(true); setRegisterSelected(false) }}>May existing account?</Button>
              </FormControl>
            </form>
          </Box>}
        <FacebookLogin userType="customer" />
        {!fbBrowserDetected && <GoogleLogin userType="customer" />}
        <AppleLogin userType="customer" />
        <Box textAlign="center" sx={{ lineHeight: 1, mt: 3, color: colors.gray3, fontSize: fontSizes.sm, fontWeight: 300 }}>
          By continuing, you agree to our <Link sx={{ color: colors.gray3, fontSize: fontSizes.sm }} href="https://homemaster.ph/terms-and-conditions" target="_blank">Terms and Conditions</Link> and <Link sx={{ color: colors.gray3, fontSize: fontSizes.sm }} href="https://homemaster.ph/privacy-policy" target="_blank">Privacy Policy</Link>
        </Box>
        {!loginsOnly &&
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button color="neutral" variant="outlined" onClick={() => navigate('/business_login')}>Log in as Business?</Button>
          </Box>}
      </Box> : <Box sx={{ height: '330px', display: 'flex', justifyContent: 'center', pt: 10 }}><BarLoader color={colors.primary} width={150} /></Box>}
    </Box>
  )
}
