import { Box, FormControl, FormLabel, Input, Select, Option } from "@mui/joy";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { GatesAndFencesJobAttributes, JobAttributes } from "../../types/job-type";

export default function GatesAndFencesForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: GatesAndFencesJobAttributes
}) {
  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl>
        <FormLabel>Service Needed</FormLabel>
        <Controller
          name="categoryAttrsValues.serviceType"
          defaultValue={attrs?.serviceType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Mamili ng kailangan mong serbisyo"
            >
              <Option value="Gate and Fence Construction">Gate and Fence Construction</Option>
              <Option value="Gate Fabrication Only">Gate Fabrication Only</Option>
              <Option value="Fence Construction Only">Fence Construction Only</Option>
              <Option value="Automatic Gate Installation">Automatic Gate Installation</Option>
              <Option value="Security Gate and Fence Solutions">Security Gate and Fence Solutions</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Estimated Gate Width (meters)</FormLabel>
        <Input {...register('categoryAttrsValues.gateWidth')} defaultValue={attrs?.gateWidth} required type="number" placeholder="E.g. 5" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Estimated Gate Height (meters)</FormLabel>
        <Input {...register('categoryAttrsValues.gateHeight')} defaultValue={attrs?.gateHeight} required type="number" placeholder="E.g. 2" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Estimated Fence Width (meters)</FormLabel>
        <Input {...register('categoryAttrsValues.fenceWidth')} defaultValue={attrs?.fenceWidth} required type="number" placeholder="E.g. 20" />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Estimated Fence Height (meters)</FormLabel>
        <Input {...register('categoryAttrsValues.fenceHeight')} defaultValue={attrs?.fenceHeight} required type="number" placeholder="E.g. 2" />
      </FormControl>
    </Box>
  )
}
