import { useContext } from 'react'
import ListItem from '@mui/joy/ListItem'
import List from '@mui/joy/List'
import ListItemButton from '@mui/joy/ListItemButton'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import WorkIcon from '@mui/icons-material/Work'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationBadge from '../components/NotificationBadge'
import ListDivider from '@mui/joy/ListDivider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom'
import { closeSidebar, isNavActive } from '../utils/nav-utils'
import { CustomerContext } from '../layouts/customer/CustomerContextProvider'
import { listStyle } from '../layouts/Sidebar'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import AssessmentIcon from '@mui/icons-material/Assessment'
import EmailIcon from '@mui/icons-material/Email'
import { Box } from '@mui/joy'
import { colors } from '../layouts/Theme'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

async function signOut() {
  await fetch('/api/v1/customer_sessions', {
    method: "DELETE"
  })

  localStorage.removeItem('hm:customer_magic_login_token')
  window.location.replace('/customer_login')
}

export default function CustomerMenu() {
  const user = useContext(CustomerContext)
  const { mobileMode } = useContext(LayoutContext)
  const navigate = useNavigate()

  function navigateThenCloseSidebar(path: string) {
    closeSidebar()
    navigate(path)
  }

  return (
    <List sx={{ mt: '30px', ...listStyle }}>
      {(user.isAmbassador || user.isAdmin) &&
        <>
          {user.isAdmin &&
            <ListItem>
              <ListItemButton onClick={() => navigateThenCloseSidebar('/customer/business-verification')} selected={isNavActive('/customer/business-verification')}>
                <VerifiedUserIcon />
                Biz Verification
              </ListItemButton>
            </ListItem>}
          {user.isAmbassador &&
            <ListItem>
              <ListItemButton onClick={() => navigateThenCloseSidebar('/customer/ambassador')} selected={isNavActive('/customer/ambassador')}>
                <AssessmentIcon />
                Ambassador
              </ListItemButton>
            </ListItem>}
          <ListDivider sx={{ mb: '10px' }} />
        </>}
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/customer/get-estimates')} selected={isNavActive('/customer/get-estimates')}>
          <PersonSearchIcon />
          Get Estimates
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/customer/messages')} selected={isNavActive('/customer/messages')}>
          <EmailIcon />
          Messages
          {user.hasUnreadMessage && <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', bgcolor: colors.red }} />}
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/customer/jobs')} selected={isNavActive('/customer/jobs')}>
          <WorkIcon />
          Job Posts
        </ListItemButton>
      </ListItem>
      {!mobileMode &&
        <ListItem>
          <ListItemButton onClick={() => navigateThenCloseSidebar('/customer/notifications')} selected={isNavActive('/customer/notifications')}>
            <NotificationsIcon />
            Notifications
            <NotificationBadge count={user.unreadNotificationsCount} />
          </ListItemButton>
        </ListItem>}
      <ListDivider sx={{ mt: '10px', mb: '15px' }} />
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/customer/account')} selected={isNavActive('/customer/account')}>
          <AccountCircleIcon />
          {user?.fullName}
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => signOut()}>
          <ExitToAppIcon />
          Sign out
        </ListItemButton>
      </ListItem>
    </List>
  )
}
