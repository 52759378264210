//@ts-expect-error: This is needed by Rails
import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import Button from '@mui/joy/Button'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import JobCategoryType from '../../types/job-category-type'
import CityType from '../../types/city-type'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CustomerContext } from '../../layouts/customer/CustomerContextProvider'
import LocationAutocomplete from '../../components/LocationAutocomplete'
import JobCategoryAutocomplete from '../../components/JobCategoryAutocomplete'
import { Box, DialogContent, Modal, ModalDialog } from '@mui/joy'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import JobCategories from '../../components/JobCategories'
import { useMutation, useQuery, useSuspenseQuery } from '@apollo/client'
import { ACTIVATE_TEMPORARY_JOB, CREATE_JOB, GET_JOBS, GET_TEMPORARY_JOB } from '../../graphql/job-queries'
import { GET_CURRENT_CUSTOMER } from '../../graphql/user-queries'
import BarLoader from 'react-spinners/BarLoader'
import { colors, fontSizes } from '../../layouts/Theme'
import { extractJobFromSignUp } from '../../utils/general-utils'
import JobType from '../../types/job-type'
import JobTypeSelectorModal from '../../components/JobTypeSelectorModal'
import PaymentRedirectNotice from '../../components/PaymentRedirectNotice'
import DownloadAppModal from '../../components/DownloadAppModal'
import JobTypeBadge from '../../components/JobTypeBadge'
import CategoryIcon from '../../components/CategoryIcon'
import dayjs from 'dayjs'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'

function ActiveJobPreview({ job } : { job: JobType }) {
  const navigate = useNavigate()

  return (
    <Box
      sx={{ position: 'relative', backgroundColor: '#FFF', padding: '20px', mb: 2, borderRadius: '20px', '&:hover': { cursor: 'pointer' } }}
      onClick={() => navigate(`/customer/job/${job.id}`)}
    >
      <JobTypeBadge jobType={job.jobType} />
      <Box sx={{ display: 'flex' }}>
        <CategoryIcon categorySlug={job.jobCategory.slug} />
        <Box>
          <Box sx={{ fontSize: fontSizes.regular, fontWeight: 600, pr: '7px' }}>{job.summary}</Box>
          <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2 }}>
            Posted {dayjs(job.createdAt).fromNow()}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mt: 2, gap: 1, fontSize: fontSizes.sm }}>
        <Box sx={{ display: 'flex', alignItems: 'center', color: colors.gray3, border: `1px solid ${colors.gray1}`, borderRadius: '16px', pl: '6px', pr: '12px', height: '30px' }}>
          <RequestQuoteIcon sx={{ mr: '5px', fontSize: '20px', color: colors.gray1 }} />Estimate: {job.estimatesCount}
        </Box>
      </Box>
    </Box>
  )
}

export default function GetEstimatesPage() {
  const navigate = useNavigate()
  const currentCustomer = useContext(CustomerContext)

  const { data: { jobs: activeJobs } } = useSuspenseQuery(GET_JOBS, { variables: { status: 'active' }})

  const showMobileNotice = !localStorage.getItem('hm:hideMobileNotice') && localStorage.getItem('hm:mode') !== 'mobile'
  const [openMobileNotice, setOpenMobileNotice] = useState(showMobileNotice)

  function closeMobileNotice() {
    setOpenMobileNotice(false)
    localStorage.setItem('hm:hideMobileNotice', 'true')
  }

  const [city, setCity] = useState<CityType | null>(currentCustomer?.lastCity || null)
  const [jobCategory, setJobCategory] = useState<JobCategoryType | null>(null)
  const [jobTypeModalOpen, setJobTypeModalOpen] = useState(false)
  const [selectedJobType, setSelectedJobType] = useState<JobType['jobType'] | null>(null)
  const [generatingInvoice, setGeneratingInvoice] = useState(false)
  const [invoiceNotRequired, setInvoiceNotRequired] = useState(false)
  const { showAlert } = useContext(LayoutContext)
  const [searchParams] = useSearchParams()

  const { data: tempJobData, loading } = useQuery(GET_TEMPORARY_JOB)

  const [createJob, { loading: creatingJob }] = useMutation(CREATE_JOB, {
    refetchQueries: [GET_CURRENT_CUSTOMER, { query: GET_JOBS }]
  })

  const [activateTemporaryJob, { loading: activatingJob }] = useMutation(ACTIVATE_TEMPORARY_JOB, {
    refetchQueries: [GET_CURRENT_CUSTOMER, { query: GET_JOBS }, { query: GET_TEMPORARY_JOB }]
  })

  localStorage.setItem('hm:customer_magic_login_token', currentCustomer.magicLoginToken)

  // This is for Email base sign ups
  useEffect(() => {
    if (!loading && tempJobData?.temporaryJob) {
      initiateJobSubmission((jobType) => {
        activateTemporaryJob({
          variables: {
            id: tempJobData.temporaryJob.id,
            jobType
          },
          onCompleted: ({ activateTemporaryJob: { errors } }) => handleJobSubmitted(errors)
        })
      })
    }
  }, [tempJobData, loading, selectedJobType])

  useEffect(() => {
    maybeSubmitNewJobFromLandingPage()
  }, [selectedJobType])

  useEffect(() => {
    if (currentCustomer) {
      setCity(currentCustomer.lastCity)
    }
  }, [currentCustomer])

  function initiateJobSubmission(onJobReadyToSubmit: (jobType: JobType['jobType']) => void) {
    let jobTypeFromParams: JobType['jobType'] | null = null

    if (searchParams.get('job_type') && ['promoted', 'high_value'].includes(searchParams.get('job_type') || '')) {
      jobTypeFromParams = searchParams.get('job_type') as JobType['jobType']
    }

    if (!jobTypeFromParams && (selectedJobType === null && !invoiceNotRequired)) {
      setJobTypeModalOpen(true)
    } else if (jobTypeFromParams || invoiceNotRequired) {
      onJobReadyToSubmit(jobTypeFromParams || selectedJobType!)
    }
  }

  // This is for Omniauth base sign ups
  function maybeSubmitNewJobFromLandingPage() {
    const jobAttributes = extractJobFromSignUp()

    if (jobAttributes) {
      initiateJobSubmission((jobType) => {
        localStorage.removeItem('hm:newJob')
        createJob({
          variables: { attributes: { ...jobAttributes, jobType } },
          onCompleted: ({ createJob: { errors } }) => handleJobSubmitted(errors)
        })
      })
    }
  }

  function handleJobSubmitted(errors: string[]) {
    if (errors.length) {
      showAlert(errors.join(', '), 'danger')
    } else {
      showAlert('Your job has been posted! Makaka receive ka ng email once meron ng estimate. Mag download ng HomeMaster app para mas mabilis kang maka receive ng notification.', 'success')
      navigate('/customer/jobs')
    }
  }


  function navigateToNewJobPage(jobCategorySlug = jobCategory?.slug) {
    if (jobCategorySlug && city) {
      navigate(`/customer/new_job/${jobCategorySlug}/location/${city.id}`)
    } else {
      showAlert('Please select location and category to get started', 'danger')
    }
  }

  function handleJobCategoryClick(jobCategory: JobCategoryType | null) {
    if (city) {
      navigateToNewJobPage(jobCategory?.slug)
    } else {
      setJobCategory(jobCategory)
    }
  }

  if (generatingInvoice) {
    return <PaymentRedirectNotice />
  }

  return (
    <Grid container spacing={1}>
      <DownloadAppModal
        openMobileNotice={openMobileNotice}
        closeMobileNotice={closeMobileNotice}
        description="Para mas madali mong ma-access at real-time ka maka receive ng estimates, download the HomeMaster app:"
      />
      <JobTypeSelectorModal
        open={jobTypeModalOpen}
        setOpen={setJobTypeModalOpen}
        onJobTypeSelect={(jobType) => {
          setSelectedJobType(jobType)
        }}
        onInvoiceNotRequired={() => {
          setJobTypeModalOpen(false)
          setInvoiceNotRequired(true)
        }}
        setGeneratingInvoice={setGeneratingInvoice}
      />
      <Modal open={creatingJob || activatingJob}>
        <ModalDialog>
          <DialogContent sx={{ textAlign: 'center' }}>
            <Typography sx={{ mb: 1 }} level="body-sm">Submitting your job</Typography>
            <BarLoader color={colors.primary} width="100%" />
          </DialogContent>
        </ModalDialog>
      </Modal>
      {!!activeJobs.length &&
        <Grid xs={12} md={9} lg={6}>
          <Typography level="h1" sx={{ mb: 2 }}>
            Your Active Jobs
          </Typography>
          {activeJobs.map((job) => (<ActiveJobPreview key={job.id} job={job} />))}
        </Grid>}
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 1 }}>
          Get Estimates
        </Typography>
      </Grid>
      <Grid xs={12} lg={9}>
        <Box sx={{ p: '20px 20px 30px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={4}>
              <FormControl>
                <FormLabel>Location</FormLabel>
                <LocationAutocomplete
                  onValueChange={(value) => setCity(value as CityType)}
                  value={city}
                  autoFocus={!activeJobs.length && !city?.id ? true : undefined}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={4}>
              <FormControl>
                <FormLabel>Category</FormLabel>
                <JobCategoryAutocomplete
                  onValueChange={(value) => setJobCategory(value as JobCategoryType)}
                  value={jobCategory}
                  autoFocus={!activeJobs.length && city?.id && !jobCategory?.id ? true : undefined}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} md={4}>
              <Box sx={{ display: 'flex', alignItems: 'end', height: '100%', mt: { xs: '5px', md: '0' } }}>
                <Button onClick={() => navigateToNewJobPage()}>
                  Get Started
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box sx={{ mt: { xs: 2, md: 3 }, p: '3px' }}>
        <JobCategories selectedJobCategory={jobCategory} onClick={handleJobCategoryClick}/>
      </Box>
    </Grid>
  )
}
