import { useContext } from "react"
import { useNavigate } from 'react-router-dom'
import ListItem from '@mui/joy/ListItem'
import List from '@mui/joy/List'
import ListItemButton from '@mui/joy/ListItemButton'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import RateReviewIcon from '@mui/icons-material/RateReview'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationBadge from "./NotificationBadge"
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import DeleteIcon from '@mui/icons-material/Delete'
import BadgeIcon from '@mui/icons-material/Badge'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { NumericFormat } from "react-number-format"
import { colors, fontSizes } from "../layouts/Theme"
import ListDivider from '@mui/joy/ListDivider'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { listStyle } from "../layouts/Sidebar"
import { closeSidebar, isNavActive } from '../utils/nav-utils'
import { BusinessUserContext } from "../layouts/business/BusinessUserContextProvider"
import { LayoutContext } from "../layouts/LayoutContextProvider"
import EmailIcon from '@mui/icons-material/Email'
import { Box } from "@mui/joy"

export default function BusinessMenu() {
  const { mobileMode } = useContext(LayoutContext)
  const user = useContext(BusinessUserContext)
  const navigate = useNavigate()

  async function signOut() {
    await fetch('/api/v1/business_user_sessions', {
      method: "DELETE"
    })

    localStorage.removeItem('hm:business_magic_login_token')
    window.location.replace('/business_login')
  }

  function navigateThenCloseSidebar(path: string) {
    closeSidebar()
    navigate(path)
  }

  return (
    <List sx={{ mt: '30px', ...listStyle }}>
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/business/leads')} selected={isNavActive('/business/leads')}>
          <PersonSearchIcon />
          Leads
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/business/messages')} selected={isNavActive('/business/messages')}>
          <EmailIcon />
          Messages
          {user.hasUnreadMessage && <Box sx={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', bgcolor: colors.red }} />}
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/business/estimates')} selected={isNavActive('/business/estimates')}>
          <RequestQuoteIcon />
          Estimates
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/business/discarded-leads')} selected={isNavActive('/business/discarded-leads')}>
          <DeleteIcon />
          Discarded Leads
        </ListItemButton>
      </ListItem>
      {!mobileMode &&
        <ListItem>
          <ListItemButton onClick={() => navigateThenCloseSidebar('/business/coverage')} selected={isNavActive('/business/coverage')}>
            <TrackChangesIcon />
            Coverage
          </ListItemButton>
        </ListItem>}
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/business/reviews')} selected={isNavActive('/business/reviews')}>
          <RateReviewIcon/>
          Reviews
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/business/profile')} selected={isNavActive('/business/profile')}>
          <BadgeIcon/>
          Business Profile
        </ListItemButton>
      </ListItem>
      {!mobileMode &&
        <ListItem>
          <ListItemButton onClick={() => navigateThenCloseSidebar('/business/notifications')} selected={isNavActive('/business/notifications')}>
            <NotificationsIcon />
            Notifications
            <NotificationBadge count={user.unreadNotificationsCount} />
          </ListItemButton>
        </ListItem>}
      <ListDivider sx={{ mt: '10px', mb: '15px' }} />
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/business/wallet')} selected={isNavActive('/business/wallet')}>
          <AccountBalanceWalletIcon />
          <span>
            Wallet
            (<NumericFormat style={{ fontSize: fontSizes.sm }} thousandSeparator="," displayType="text" prefix="₱" value={user.walletBalance} />)
          </span>
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => navigateThenCloseSidebar('/business/account')} selected={isNavActive('/business/account')}>
          <AccountCircleIcon />
          {user.fullName}
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton onClick={() => signOut()}>
          <ExitToAppIcon />
          Sign out
        </ListItemButton>
      </ListItem>
    </List>
  )
}
