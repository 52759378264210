import { Box, Link, Typography } from '@mui/joy'
import { Link as RouterLink } from 'react-router-dom'
import BusinessLogo from './BusinessLogo'
import BusinessProfileType from '../types/business-profile-type'
import { Rating } from '@mui/material'
import { BusinessReviewType } from '../types/review-type'
import { colors, fontSizes } from '../layouts/Theme'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocationOnIcon from '@mui/icons-material/LocationOn'

dayjs.extend(relativeTime)

type Props = {
  businessProfile: BusinessProfileType
  jobId?: string
  businessUserId?: string
  reviews?: BusinessReviewType[]
  hideReviews?: boolean
}

export default function BusinessHeader({ businessProfile: { name, logoUrl, address }, jobId, businessUserId, reviews = [], hideReviews = false }: Props) {
  const averageRating = reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
      <BusinessLogo logoUrl={logoUrl} size="60px" />
      <Box>
        <Box>
          <Typography level="title-lg">{name}</Typography>
          <Typography level="body-sm" sx={{ color: colors.gray3 }}>
            <LocationOnIcon sx={{ ml: '-5px', fontSize: '16px' }} />{address}
          </Typography>
          {!hideReviews &&
            <Box sx={{ display: 'flex' }}>
              {!!reviews.length &&
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography level="title-md" sx={{ mr: '3px' }}>{averageRating.toFixed(1)}</Typography>
                  <Rating readOnly value={averageRating} precision={0.5} size="small" />
                  <Link component={RouterLink} to={`/customer/job/${jobId}/business/${businessUserId}/reviews`} level="body-xs" sx={{ ml: 1 }}>View ({reviews.length})</Link>
                </Box>}
            </Box>}
        </Box>
      </Box>
    </Box>
  )
}
