//@ts-expect-error: This is needed by Rails
import React, { Fragment, useContext, useEffect, useState } from 'react'
import Typography from '@mui/joy/Typography'
import { GET_LEADS } from '../../graphql/job-queries'
import JobType from '../../types/job-type'
import { useSuspenseQuery } from '@apollo/client'
import Grid from '@mui/joy/Grid'
import LeadCard from '../../components/LeadCard'
import { useNavigate } from 'react-router-dom'
import { BusinessUserContext } from '../../layouts/business/BusinessUserContextProvider'
import { Box, Button } from '@mui/joy'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import { colors } from '../../layouts/Theme'
import DownloadAppModal from '../../components/DownloadAppModal'
import { LayoutContext } from '../../layouts/LayoutContextProvider'

export default function LeadsPage() {
  const navigate = useNavigate()
  const { data: { leads } } = useSuspenseQuery(GET_LEADS, { fetchPolicy: 'network-only' })
  const businessUser = useContext(BusinessUserContext)

  const showMobileNotice = !localStorage.getItem('hm:hideMobileNotice') && localStorage.getItem('hm:mode') !== 'mobile'
  const [openMobileNotice, setOpenMobileNotice] = useState(showMobileNotice)

  const { mobileMode } = useContext(LayoutContext)

  function closeMobileNotice() {
    setOpenMobileNotice(false)
    localStorage.setItem('hm:hideMobileNotice', 'true')
  }

  localStorage.setItem('hm:business_magic_login_token', businessUser.magicLoginToken)

  return (
    <Grid container>
      <DownloadAppModal
        openMobileNotice={openMobileNotice}
        closeMobileNotice={closeMobileNotice}
        description="Para mas madali mong ma-access at real-time ka maka receive ng estimates, download the HomeMaster app:"
      />
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>Leads</Typography>
      </Grid>
      {businessUser.businessProfile?.coverageCompleted ?
        <>
          {leads.length ?
            leads.map((job: JobType) => {
              return (
                <Fragment key={job.id}>
                  <Grid xs={12} md={9} lg={6}>
                    <LeadCard job={job} />
                  </Grid>
                  <Grid lg={6} />
                </Fragment>
            )}) :
            <Grid xs={12} md={9} lg={6}>
              <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ textAlign: 'center' }}>
                  <PersonSearchIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
                  <p>Wala pang leads at the moment. Makakatanggap ka ng email sa {businessUser.email} once mayroon ng pumasok na lead.</p>
                  {!mobileMode &&
                    <Box sx={{ mt: 1 }}>
                      Para real-time ang notification, mag download at mag log in gamit ang HomeMaster mobile app
                      <Box sx={{ mt: 2, display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <a href="https://play.google.com/store/apps/details?id=ph.homemaster.android"><img src="/google-play-badge.png" width="150px" /></a>
                        <a href="https://apps.apple.com/us/app/homemaster-ph/id6737192957"><img src="/app-store-badge.png" width="135px" /></a>
                      </Box>
                    </Box>}
                </Box>
              </Box>
            </Grid>
          }
        </> :
        <Grid xs={12} md={9} lg={6}>
          <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <PersonSearchIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
              <p>Please complete your coverage to see leads</p>
              <Button variant="outlined" color="neutral" onClick={() => navigate('/business/coverage')}>Complete Coverage</Button>
            </Box>
          </Box>
        </Grid>}
    </Grid>
  )
}
