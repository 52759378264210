import { TypedDocumentNode, gql } from '@apollo/client'
import MessageType from '../types/message-type'

export const READ_MESSAGES_AS_CUSTOMER: TypedDocumentNode<{ readMessagesAsCustomer: { errors: [string], success: boolean } }, { estimateId: string, timestamp: string }> = gql`
  mutation ReadMessagesAsCustomer($estimateId: ID!, $timestamp: String!) {
    readMessagesAsCustomer(input: { estimateId: $estimateId, timestamp: $timestamp }) {
      success
      errors
    }
  }
`

export const READ_MESSAGES_AS_BUSINESS: TypedDocumentNode<{ readMessagesAsBusiness: { errors: [string], success: boolean } }, { estimateId: string, timestamp: string }> = gql`
  mutation ReadMessagesAsBusiness($estimateId: ID!, $timestamp: String!) {
    readMessagesAsBusiness(input: { estimateId: $estimateId, timestamp: $timestamp }) {
      success
      errors
    }
  }
`

export const GET_MESSAGES: TypedDocumentNode<{ messages: MessageType[] }, { conversationId: string }> = gql`
  query GetMessages($conversationId: ID!) {
    messages(conversationId: $conversationId) {
      id
      content
      conversationId
      conversationType
      recipientType
      recipientId
      senderType
      senderId
      createdAt
    }
  }
`

export const CREATE_MESSAGE_AS_BUSINESS: TypedDocumentNode<{ createMessageAsBusiness: { errors: [string], message: MessageType } }, { estimateId: string, content: string }> = gql`
  mutation CreateMessageAsBusiness($estimateId: ID!, $content: String!) {
    createMessageAsBusiness(input: { estimateId: $estimateId, content: $content }) {
      message {
        id
        conversationId
        conversationType
        recipientType
        recipientId
        senderType
        senderId
        content
        createdAt
      }
      errors
    }
  }
`

export const CREATE_MESSAGE_AS_CUSTOMER: TypedDocumentNode<{ createMessageAsCustomer: { errors: [string], message: MessageType } }, { estimateId: string, content: string }> = gql`
  mutation CreateMessageAsCustomer($estimateId: ID!, $content: String!) {
    createMessageAsCustomer(input: { estimateId: $estimateId, content: $content }) {
      message {
        id
        conversationId
        conversationType
        recipientType
        recipientId
        senderType
        senderId
        content
        createdAt
      }
      errors
    }
  }
`
