import { Box, FormControl, FormLabel, Input, Select, Option } from "@mui/joy";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { FurnitureJobAttributes, JobAttributes } from "../../types/job-type";

export default function FurnitureForm({ control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: FurnitureJobAttributes
}) {
  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl>
        <FormLabel>Type of Furniture</FormLabel>
        <Controller
          name="categoryAttrsValues.furnitureType"
          defaultValue={attrs?.furnitureType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Mamili ng klase ng furniture"
            >
              <Option value="Dining Table">Dining Table</Option>
              <Option value="Cabinet">Cabinet</Option>
              <Option value="Sofa">Sofa</Option>
              <Option value="Chair">Chair</Option>
              <Option value="Bed">Bed</Option>
              <Option value="Coffee Table">Coffee Table</Option>
              <Option value="Console Table">Console Table</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}
