import { Box, FormControl, Grid, Tab, TabList, Tabs, Typography, tabClasses } from '@mui/joy'
import { useContext, useEffect, useState } from 'react'
import JobCategoryType from '../types/job-category-type'
import JobCategoryAutocomplete from '../components/JobCategoryAutocomplete'
import JobCategories, { CATEGORY_GROUPS } from '../components/JobCategories'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { colors, colorsV2 } from '../layouts/Theme'
import PostAddIcon from '@mui/icons-material/PostAdd'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'

export default function HomePage() {
  const [searchParams] = useSearchParams()
  const [jobCategory] = useState<JobCategoryType | null>(null)
  const [categoryGroup, setCategoryGroup] = useState<(keyof typeof CATEGORY_GROUPS)>('newHouse')
  const { showAlert } = useContext(LayoutContext)
  const navigate = useNavigate()
  const { code } = useParams()
  const { mobileMode } = useContext(LayoutContext)

  useEffect(() => {
    localStorage.setItem('hm:source', searchParams.get('ref') || 'organic')

    if (code) {
      localStorage.setItem('hm:referralCode', code)
    } else {
      localStorage.removeItem('hm:referralCode')
    }
  }, [])

  function setCategoryGroupByTabValue(value: string | number | null) {
    switch(value) {
      case 0:
        setCategoryGroup('newHouse')
        break
      case 1:
        setCategoryGroup('improvement')
        break
      case 2:
        setCategoryGroup('maintenance')
        break
    }
  }

  function navigateToNewJobPage(jobCategorySlug = jobCategory?.slug) {
    if (jobCategorySlug) {
      navigate(`/get_estimates/${jobCategorySlug}`)
    } else {
      showAlert('Please select a category to get started', 'danger')
    }
  }

  function handleJobCategoryClick(jobCategory: JobCategoryType | null) {
    navigateToNewJobPage(jobCategory?.slug)
  }

  return (
    <>
      <Grid xs={12} 
        sx={{
          display: 'flex', flexDirection: 'column', bgcolor: '#FAFAFA',
          alignItems: 'center', px: { xs: 4 }, height: { xs: '440px', md: '380px' }, pt: { xs: 3, md: 5 },
          backgroundImage: 'url(/banner.png)', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
      }}>
        <Typography sx={{ textAlign: { xs: 'left', sm: 'center' }, fontSize: { xs: '35px', sm: '50px' }, fontWeight: 900, color: colorsV2.black, lineHeight: '1em', mb: 3 }}>
          Get free estimates for any home project
        </Typography>
        <Box sx={{ width: { xs: '100%', md: '50%' } }}>
          <Typography sx={{ mb: 1, fontSize: '20px', fontWeight: 600, color: colorsV2.black }}>
            Ano ang plano mong ipagawa?
          </Typography>
          <FormControl sx={{ width: '100%', mt: 1, mb: 5, '.MuiAutocomplete-root': { p: '7px 20px' } }}>
            <JobCategoryAutocomplete
              placeholder="e.g. Construction, Plumbing, Curtains"
              onValueChange={(value) => {
                const category = value as JobCategoryType
                navigateToNewJobPage(category.slug)
              }}
              value={jobCategory}
              autoFocus={false}
            />
          </FormControl>
        </Box>
      </Grid>
      {!mobileMode &&
        <Grid xs={12} sx={{ bgcolor: '#FAFAFA', px: { xs: 4 }, py: { xs: 2, md: 4 } }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <a href="https://play.google.com/store/apps/details?id=ph.homemaster.android"><img src="/google-play-badge.png" width="150px" /></a>
            <a href="https://apps.apple.com/us/app/homemaster-ph/id6737192957"><img src="/app-store-badge.png" width="135px" /></a>
          </Box>
        </Grid>}
      <Grid xs={12} sx={{ bgcolor: 'white', px: { xs: 4, md: 30 }, py: { xs: 4, md: 5 } }}>
        <Typography sx={{ mb: { xs: 3, md: 5 }, fontSize: '26px', fontWeight: 800, textAlign: 'center', color: colorsV2.black }}>How HomeMaster Works</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: { xs: 'column', md: 'row' }, mb: 1, gap: { xs: 2, md: 5 }, px: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'left', md: 'center' } }}>
            <PostAddIcon sx={{ fontSize: '50px', mb: 2, color: colorsV2.gray2, display: { xs: 'none', md: 'block' } }} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{
                bgcolor: colors.primary, fontWeight: 600, color: 'white', display: 'flex', justifyContent: 'center',
                width: '25px', height: '25px', alignItems: 'center', borderRadius: '50%', fontSize: '14px'
              }}>
                <span>1</span>
              </Box>
              <Box sx={{ ml: 1 }}>Mag post ng service na kailangan mo</Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'left', md: 'center' } }}>
            <RequestQuoteIcon sx={{ fontSize: '50px', mb: 2, color: colorsV2.gray2, display: { xs: 'none', md: 'block' } }} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{
                bgcolor: colors.primary, fontWeight: 600, color: 'white', display: 'flex', justifyContent: 'center',
                width: '25px', height: '25px', alignItems: 'center', borderRadius: '50%', fontSize: '14px'
              }}>
                <span>2</span>
              </Box>
              <Box sx={{ ml: 1 }}>Receive quotes from multiple providers</Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'left', md: 'center' } }}>
            <PersonSearchIcon sx={{ fontSize: '50px', mb: 2, color: colorsV2.gray2, display: { xs: 'none', md: 'block' } }} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{
                bgcolor: colors.primary, fontWeight: 600, color: 'white', display: 'flex', justifyContent: 'center',
                width: '25px', height: '25px', alignItems: 'center', borderRadius: '50%', fontSize: '14px'
              }}>
                <span>3</span>
              </Box>
              <Box sx={{ ml: 1 }}>Choose which provider to hire</Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} sx={{ bgcolor: colorsV2.background, pt: 4, pb: 2, px: { xs: 4, md: 30 }}}>
        <Typography sx={{ fontSize: '26px', fontWeight: 800, color: colorsV2.black }}>Categories</Typography>
      </Grid>
      <Grid xs={12} sx={{ bgcolor: colorsV2.background, px: { xs: 4, md: 30 } }}>
        <Tabs
          variant="outlined"
          defaultValue={0}
          onChange={(_e, value) => setCategoryGroupByTabValue(value)}
          sx={{ width: { xs: '100%' }, borderRadius: 'lg', overflow: 'auto', border: 0, bgcolor: 'transparent' }}
        >
          <TabList
            disableUnderline
            tabFlex={1}
            sx={{
              p: 0.75,
              gap: 1,
              borderRadius: 'xl',
              bgcolor: 'background.level1',
              fontWeight: 500,
              fontSize: { xs: '12px', md: '14px' },
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: 'sm',
                bgcolor: 'background.surface',
              },
            }}
          >
            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
              New House
            </Tab>
            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
              Improvement
            </Tab>
            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
              Maintenance
            </Tab>
          </TabList>
        </Tabs>
      </Grid>
      <Grid xs={12} sx={{ bgcolor: colorsV2.background, pt: { xs: 2, sm: 3, md: 5 }, px: { xs: 4, md: 30 }, pb: 5 }}>
        <JobCategories selectedJobCategory={jobCategory} onClick={handleJobCategoryClick} categoryGroupFilter={categoryGroup}/>
      </Grid>
    </>
  )
}
