import { Box, Grid } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_MESSAGES, READ_MESSAGES_AS_BUSINESS } from '../../graphql/messages-queries'
import { GET_ESTIMATE } from '../../graphql/estimate-queries'
import PageNotFound from '../PageNotFound'
import MessageWidget from '../../components/MessageWidget'
import { useContext, useEffect } from 'react'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import { ActionCableProvider } from '../../hooks/ActionCableContext'

export default function BusinessViewMessagePage() {
  const { estimateId } = useParams()
  const { data: { estimate } } = useSuspenseQuery(GET_ESTIMATE, { variables: { id: estimateId || '' } })
  if (!estimate) { return <PageNotFound /> }

  const { showAlert } = useContext(LayoutContext)
  const { job } = estimate
  const { data: { messages: messageHistory } } = useSuspenseQuery(GET_MESSAGES, { variables: { conversationId: estimate.id }, fetchPolicy: 'network-only' })

  const [readMessagesAsBusiness] = useMutation(READ_MESSAGES_AS_BUSINESS, {
    onCompleted: ({ readMessagesAsBusiness: { errors }}) => {
      if(errors.length) {
        showAlert(errors.join(', '), 'danger')
      }
    }
  })

  useEffect(() => {
    if (messageHistory.length) {
      const lastMessage = messageHistory[messageHistory.length - 1]
      readMessagesAsBusiness({ variables: { estimateId: estimate.id, timestamp: lastMessage.createdAt }})
    }
  }, [messageHistory])

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Box>
          <ActionCableProvider>
            <MessageWidget
              title={estimate.customerName}
              subtitle={job.summary}
              estimate={estimate}
              messageHistory={messageHistory}
              conversationId={estimate.id}
              senderType="BusinessUser"
            />
          </ActionCableProvider>
        </Box>
      </Grid>
    </Grid>
  )
}
