import { Box } from '@mui/joy'
import { colors } from '../layouts/Theme'

export default function Avatar({ avatarUrl, userInitial, size = 40 }: { size?: number, avatarUrl?: string, userInitial?: string }) {
  if (avatarUrl) {
    const adjustedSize = `${size - 5}px`
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={avatarUrl} style={{ objectFit: 'cover', width: adjustedSize, height: adjustedSize, borderRadius: '50%' }} />
      </Box>
    )
  } else {
    return (
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        width: `${size}px`, height: `${size}px`, fontSize: '20px', bgcolor: colors.primaryMuted,
        borderRadius: '50%', color: colors.primary, fontWeight: 500 }}>
        {userInitial}
      </Box>
    )
  }
}
