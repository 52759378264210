import { Box, Grid, Typography } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import { GET_ESTIMATE_CONVERSATIONS_FOR_BUSINESS } from '../../graphql/estimate-queries'
import { useSuspenseQuery } from '@apollo/client'
import { colors } from '../../layouts/Theme'
import Avatar from '../../components/Avatar'
import EmailIcon from '@mui/icons-material/Email'

export default function BusinessMessagesPage() {
  const navigate = useNavigate()
  const { data: { estimateConversationsForBusiness } } = useSuspenseQuery(GET_ESTIMATE_CONVERSATIONS_FOR_BUSINESS, { fetchPolicy: 'network-only' })

  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <Typography level="h1" sx={{ mb: 2 }}>Messages</Typography>
        <Box sx={{ mt: 3, display: 'flex', bgcolor: 'white', borderRadius: '15px', flexDirection: 'column' }}>
          {!!estimateConversationsForBusiness.length ?
            estimateConversationsForBusiness.map(({ id, hasUnreadForBusiness, job: { summary, customer }}) => (
              <Box
                onClick={() => navigate(`/business/messages/${id}`)}
                key={id}
                sx={{
                  '&:hover': { cursor: 'pointer' },
                  display: 'flex',
                  alignItems: 'center',
                  p: '10px 20px 10px 12px', borderBottom: '1px solid var(--joy-palette-neutral-300)',
                  '&:last-of-type': { borderBottom: 'none' }
                }}
              >
                <Box sx={{ mr: 1.5 }}>
                  <Avatar userInitial={customer.fullName[0]} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box>
                    <Box sx={{ fontWeight: hasUnreadForBusiness ? 600 : 400 }}>{customer.fullName}</Box>
                    <Box sx={{ fontSize: '12px', color: colors.gray3, mt: '-3px' }}>{summary}</Box>
                  </Box>
                  {hasUnreadForBusiness && <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', bgcolor: colors.primary }} />}
                </Box>
              </Box>
            )) :
            <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ textAlign: 'center' }}>
                <EmailIcon sx={{ fontSize: '30px', color: colors.gray2 }} />
                <p>Wala ka pang messages</p>
              </Box>
            </Box>
          }
        </Box>
      </Grid>
    </Grid>
  )
}
