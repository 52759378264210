import { Box, Button, FormControl, FormLabel, Grid, Input, Typography } from '@mui/joy'
import { useContext, useState } from 'react'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import { useNavigate, useParams } from 'react-router-dom'
import useCsrfToken from '../../hooks/use-csrf-token'

export default function CustomerResetPasswordPage() {
  const navigate = useNavigate()
  const csrfToken = useCsrfToken()
  const { token } = useParams()
  const { showAlert } = useContext(LayoutContext)
  const [submitting, setSubmitting] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    try {
      setSubmitting(true)
      const response = await fetch('/api/v1/customer_reset_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken!
        },
        body: JSON.stringify({ password, password_confirmation: passwordConfirmation, reset_password_token: token })
      })

      const json = await response.json()

      if (response.ok) {
        navigate('/customer_login')
        showAlert(json.message, 'success')
      } else {
        setSubmitting(false)
        showAlert(json.message, 'danger')
      }
    } catch(error) {
      alert("Something went wrong! We're on it")
      console.error(error)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} md={5} sx={{ pt: 4, pb: 4, px: { xs: 4, md: 30 } }}>
        <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <Typography level="title-lg">
            Reset Password
          </Typography>
          <form onSubmit={onSubmit}>
            <FormControl sx={{ mt: 2, mb: 1 }}>
              <FormLabel>Password</FormLabel>
              <Input required autoFocus
                type="password"
                placeholder="Your new password"
                value={password}
                onChange={({ target: { value }}) => setPassword(value)}
              />
            </FormControl>
            <FormControl sx={{ mt: 2, mb: 1 }}>
              <FormLabel>Password Confirmation</FormLabel>
              <Input required
                type="password"
                placeholder="Re-type your new password"
                value={passwordConfirmation}
                onChange={({ target: { value }}) => setPasswordConfirmation(value)}
              />
            </FormControl>
            <Button sx={{ mt: 2 }} type="submit" disabled={submitting}>Reset Password</Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  )
}
