import { Box, Typography } from '@mui/joy'
import { FurnitureJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function FurnitureDetails({ attrs }: { attrs: FurnitureJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Type of Furniture</Typography>
        <Typography level="title-sm">{attrs.furnitureType}</Typography>
      </Box>
    </Box>
  )
}
