import { Box, Grid, Typography } from '@mui/joy'
import CustomerLoginOptions from '../components/CustomerLoginOptions'

export default function NewJobLogInPage() {
  return (
    <Grid container justifyContent="center" sx={{ pt: 2, pb: 4, px: { xs: 3 }}}>
      <Grid xs={12} md={7}>
        <Box sx={{ p: '10px', pt: 0, mb: 1 }}>
          <Typography level="h4" sx={{ mt: 1, lineHeight: '1.5rem' }}>Mag log in or create ng account</Typography>
          <Typography level="body-sm" sx={{ fontSize: '14px' }}>Libre lang ang mag join. Siguraduhing tama ang iyong Email dahil dito mo matatanggap ang estimates</Typography>
        </Box>
        <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <CustomerLoginOptions loginsOnly signUpFirst/>
        </Box>
      </Grid>
    </Grid>
  )
}
