//@ts-expect-error: This is needed by Rails
import React, { useContext, useEffect, useState } from 'react'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import { GET_JOBS } from '../../graphql/job-queries'
import { useSuspenseQuery } from '@apollo/client'
import Box from '@mui/joy/Box'
import { colors } from '../../layouts/Theme'
import { useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button } from '@mui/joy'
import MarkAsCompletedModal from '../../components/MarkAsCompletedModal'
import WorkIcon from '@mui/icons-material/Work'
import AddBusinessReviewModal from '../../components/AddBusinessReviewModal'
import EstimateType from '../../types/estimate-type'
import JobCard from '../../components/JobCard'
import JobType from '../../types/job-type'
import DownloadAppModal from '../../components/DownloadAppModal'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
dayjs.extend(relativeTime)

export default function JobsPage() {
  const { data: { jobs } } = useSuspenseQuery(GET_JOBS)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const fromRegistration = !!searchParams.get('from_registration') || false
  const [selectedJob, setSelectedJob] = useState<JobType | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [reviewModalOpen, setReviewModalOpen] = useState(false)
  const [winningEstimate, setWinningEstimate] = useState<EstimateType | null>(null)
  const [openMobileNotice, setOpenMobileNotice] = useState(fromRegistration)
  const { mobileMode } = useContext(LayoutContext)

  const { showAlert } = useContext(LayoutContext)

  useEffect(() => {
    if (mobileMode && fromRegistration) {
      showAlert('Your job was posted successfully')
    }
  }, [])

  function openModal(job: JobType) {
    setSelectedJob(job)
    setModalOpen(true)
  }

  function onJobCompleted(winningEstimate: EstimateType) {
    setWinningEstimate(winningEstimate)
    setReviewModalOpen(true)
  }

  return (
    <Grid container>
      {!mobileMode &&
        <DownloadAppModal
          openMobileNotice={openMobileNotice}
          closeMobileNotice={() => setOpenMobileNotice(false)}
          title="Your job was posted!"
          description="Para mas madali mong ma-access at real-time ka maka receive ng estimates, download at mag log in sa HomeMaster app:"
        />}
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>Jobs</Typography>
      </Grid>
      {selectedJob && <MarkAsCompletedModal open={modalOpen} setOpen={setModalOpen} job={selectedJob} onJobCompleted={onJobCompleted} />}
      {winningEstimate &&
        <AddBusinessReviewModal open={reviewModalOpen} setOpen={setReviewModalOpen} businessUser={winningEstimate.businessUser} jobId={winningEstimate.jobId} />}
      <Grid xs={12} md={9} lg={6}>
        {jobs.length ?
          jobs.map((job) => {
            return (
              <JobCard key={job.id} job={job} onCompleted={openModal} />
            ) 
        }) :
        <Box sx={{ p: '30px 20px 30px', backgroundColor: '#FFF', borderRadius: '10px', color: colors.gray3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ textAlign: 'center' }}>
            <WorkIcon sx={{ fontSize: '30px', color: colors.gray2 }}/>
            <p>You have not added any jobs yet</p>
            <Button variant="outlined" color="neutral" onClick={() => navigate('/customer/get-estimates')}>Post a Job</Button>
          </Box>
        </Box>}
      </Grid>
    </Grid>
  )
}
