//@ts-expect-error: This is needed by Rails
import React, { useEffect, useState } from "react"
import JobCategoryType from "../types/job-category-type"
import { useQuery } from "@apollo/client"
import { GET_JOB_CATEGORIES } from "../graphql/job-category-queries"
import { Autocomplete, AutocompleteOption, Box } from "@mui/joy"
import { fontSizes, colors } from "../layouts/Theme"

interface JobCategoryAutocompleteProps {
  onValueChange: (id: JobCategoryType | JobCategoryType[] | null) => void
  value: JobCategoryType | JobCategoryType[] | null
  autoFocus: boolean | undefined
  multiple?: boolean
  placeholder?: string
}

export default function JobCategoryAutocomplete({
  onValueChange,
  value,
  autoFocus,
  multiple = false,
  placeholder = "Search o pumili ng category"
}: JobCategoryAutocompleteProps) {
  const [options, setOptions] = useState<JobCategoryType[]>([])
  const { loading, data, } = useQuery(GET_JOB_CATEGORIES)

  useEffect(() => {
    if(data && !loading) {
      setOptions(data.jobCategories)
    }
  })

  return (
    <Autocomplete
      sx={{ 'input': { margin: 0 }}}
      disableCloseOnSelect={multiple}
      autoFocus={autoFocus}
      multiple={multiple}
      onChange={(_event, val) => onValueChange(val)}
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value?.id
      }}
      getOptionLabel={(option) => option.name}
      filterOptions={(options, state) => {
        const searchString = state.inputValue?.toLowerCase()

        return options.filter((option) => (
          option.name.toLowerCase().includes(searchString) || option.description.toLowerCase().includes(searchString)
        ))
      }}
      placeholder={placeholder}
      noOptionsText="Category not found"
      options={options}
      renderOption={(props, option) => (
        <AutocompleteOption {...props}>
          <Box>
            {option.name}
            <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2 }}>{option.description}</Box>
          </Box>
        </AutocompleteOption>
      )}
    />
  )
}
