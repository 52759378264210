//@ts-expect-error: This is needed by Rails
import React, { useContext } from "react"
import { Box, IconButton } from '@mui/joy'
import MenuIcon from '@mui/icons-material/Menu'
import { toggleSidebar } from '../utils/nav-utils'
import NotificationBadge from "../components/NotificationBadge"
import { BusinessUserContext } from "./business/BusinessUserContextProvider"
import { CustomerContext } from "./customer/CustomerContextProvider"
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { LayoutContext } from "./LayoutContextProvider"
import { isEmpty } from "lodash"
import EmailIcon from '@mui/icons-material/Email'
import { colors } from "./Theme"

const noHeaderPaths = [
  '/customer/notifications',
  '/business/notifications',
  '/business/coverage',
]

export default function Header() {
  const businessUser = useContext(BusinessUserContext)
  const customer = useContext(CustomerContext)
  const notificationCount = businessUser?.unreadNotificationsCount || customer?.unreadNotificationsCount
  const hasUnreadMessage = businessUser?.hasUnreadMessage || customer?.hasUnreadMessage
  const homePath = isEmpty(businessUser) ? '/customer/get-estimates' : '/business/leads'
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { mobileMode } = useContext(LayoutContext)

  const hideHeader = mobileMode && (noHeaderPaths.includes(location.pathname) || searchParams.get('ref') === 'notifications')

  return (
    <Box
      sx={{
        display: { xs: hideHeader ? 'none' : 'flex', sm: 'none' },
        borderBottom: '1px solid #CCC',
        position: 'sticky',
        zIndex: 99,
        backgroundColor: 'white',
        top: 0,
        pb: '5px',
        pt: '5px',
        px: '20px',
        mb: '5px',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <IconButton
        variant="plain"
        color="neutral"
        size="sm"
        sx={{ px: '7px', mr: '15px', mt: '5px' }}
        onClick={() => toggleSidebar()}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <MenuIcon />
          <Box sx={{ mt: '-3px', fontSize: '10px', textTransform: 'uppercase' }}>Menu</Box>
        </Box>
      </IconButton>
      <Box onClick={() => navigate(homePath)}>
        <img src="/hm-icon.png" style={{ width: '55px', marginRight: mobileMode ? '17px' : '0' }} />
      </Box>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', '&:hover': { cursor: 'pointer' }}} onClick={() => navigate(isEmpty(businessUser) ? '/customer/messages' : '/business/messages')}>
          <Box sx={{ position: 'relative' }}>
            <EmailIcon sx={{ fontSize: '27px' }} />
            {hasUnreadMessage &&
              <Box sx={{ position: 'absolute', top: '0px', left: '20px', width: '12px', height: '12px', borderRadius: '50%', backgroundColor: colors.red }} />}
          </Box>
        </Box>
        <Box sx={{ display: mobileMode? 'none' : 'flex', '&:hover': { cursor: 'pointer' }}} onClick={() => navigate(isEmpty(businessUser) ? '/customer/notifications' : '/business/notifications')}>
          <NotificationsIcon sx={{ fontSize: '30px' }}/>
          {!!notificationCount &&
            <Box sx={{ position: 'absolute', top: '20px', right: '13px' }}>
              <NotificationBadge count={notificationCount} />
            </Box>}
        </Box>
      </Box>
    </Box>
  )
}
