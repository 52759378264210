//@ts-expect-error: This is needed by Rails
import React, { useContext, useState } from 'react'
import { Grid, IconButton } from '@mui/joy'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_JOB_CATEGORY } from '../../graphql/job-category-queries'
import { GET_JOBS } from '../../graphql/job-queries'
import { GET_CITY } from '../../graphql/location-queries.ts'
import { JobAttributes } from '../../types/job-type'
import { CREATE_JOB } from '../../graphql/job-queries'
import { GET_CURRENT_CUSTOMER } from '../../graphql/user-queries'
import JobForm from '../../components/JobForm.tsx'
import { LayoutContext } from '../../layouts/LayoutContextProvider.tsx'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

export default function NewJobPage() {
  const navigate = useNavigate()
  const { categorySlug, locationId } = useParams()
  const { showAlert } = useContext(LayoutContext)

  const { data: { city }} = useSuspenseQuery(GET_CITY, { variables: { id: locationId! } })
  const { data: { jobCategory }} = useSuspenseQuery(GET_JOB_CATEGORY, { variables: { slug: categorySlug! } })
  const [createJob, { loading: formSubmitting }] = useMutation(CREATE_JOB, {
    refetchQueries: [GET_CURRENT_CUSTOMER, { query: GET_JOBS }]
  })

  function onSubmit(data: JobAttributes) {
    const job = { ...data, cityId: city!.id, jobCategoryId: jobCategory!.id }

    createJob({
      variables: { attributes: job },
      onCompleted: ({ createJob: { errors } }) => {
        if (errors.length) {
          showAlert(errors.join(', '), 'danger')
        } else {
          showAlert('Your job has been posted! Makaka receive ka ng email once meron ng estimate. Mag download ng HomeMaster app para mas mabilis kang maka receive ng notification.', 'success')
          navigate('/customer/jobs')
        }
      },
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <IconButton sx={{ pl: '5px', pr: '10px' }} onClick={() => navigate('/customer/get-estimates')}>
          <KeyboardArrowLeftIcon sx={{ mr: 0 }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12} lg={8} sx={{ mb: '20px' }}>
        <JobForm onSubmit={onSubmit} saving={formSubmitting} submitLabel="Post & Get Estimates" city={city} jobCategory={jobCategory} />
      </Grid>
    </Grid>
  )
}
