//@ts-expect-error: This is needed by Rails
import React from 'react'
import Box from '@mui/joy/Box'
import { colors, colorsV2 } from '../layouts/Theme'
import JobCategoryType from '../types/job-category-type'
import { renderIcon } from '../utils/render-utils'

type CategoryCardProps = {
  jobCategory: JobCategoryType,
  selected: boolean,
  onClick: (jobCategory: JobCategoryType | null) => void
}

export default function CategoryCard({ jobCategory, selected = false, onClick }: CategoryCardProps) {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: { xs: '10px', md: '20px', lg: '25px' },
        borderRadius: { xs: '15px', md: '20px' },
        height: { xs: '100px', md: '130px' },
        '&:hover': {
          outline: `2px solid ${colors.primary}`,
          cursor: 'pointer',
        },
        outline: selected ? `2px solid ${colors.primary}` : 'none',
        display: { xs: 'flex' },
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column'
      }}
      onClick={() => { onClick(jobCategory) }}
    >
      <Box sx={{ color: colors.primary, textAlign: 'center', mr: 1, mb: 1 }}>
        {renderIcon(jobCategory.slug)}
      </Box>
      <Box>
        <Box sx={{ color: colorsV2.black, textAlign: 'center', fontSize: { xs: '12px', md: '14px' }, fontWeight: 500, lineHeight: { xs: '1em' } }}>
          {jobCategory.name}
        </Box>
      </Box>
    </Box>
  )
}
