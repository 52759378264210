import { Box, Button, FormControl, FormLabel, Grid, Input, Typography } from '@mui/joy'
import { useContext, useState } from 'react'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import { useNavigate } from 'react-router-dom'
import useCsrfToken from '../../hooks/use-csrf-token'

export default function CustomerForgotPasswordPage() {
  const navigate = useNavigate()
  const csrfToken = useCsrfToken()
  const { showAlert } = useContext(LayoutContext)
  const [submitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState('')

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    try {
      setSubmitting(true)
      const response = await fetch('/api/v1/send_customer_forgot_password_link', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken!
        },
        body: JSON.stringify({ email })
      })

      const json = await response.json()

      if (response.ok) {
        navigate('/customer_login')
        showAlert(json.message, 'success')
      } else {
        setSubmitting(false)
        showAlert(json.message, 'danger')
      }
    } catch(error) {
      alert("Something went wrong! We're on it")
      console.error(error)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} md={5} sx={{ pt: 4, pb: 4, px: { xs: 4, md: 30 } }}>
        <Box sx={{ p: '20px', backgroundColor: '#FFF', borderRadius: '20px' }}>
          <Typography level="title-lg">
            Forgot Password
          </Typography>
          <form onSubmit={onSubmit}>
            <FormControl sx={{ mt: 2, mb: 1 }}>
              <FormLabel>Email Address</FormLabel>
              <Input required autoFocus
                type="text"
                placeholder="Your Email Address"
                value={email}
                onChange={({ target: { value }}) => setEmail(value)}
              />
            </FormControl>
            <Button sx={{ mt: 2 }} type="submit" disabled={submitting}>Forgot Password</Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  )
}
