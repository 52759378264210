import React, { useEffect, useState } from 'react'
import { useQuery } from "@apollo/client"
import { Autocomplete } from "@mui/joy"
import CityType from "../types/city-type"
import { GET_CITIES } from "../graphql/location-queries"

interface LocationAutocompleteProps {
  onValueChange: (value: CityType | CityType[] | null) => void
  value: CityType | CityType[] | null
  autoFocus: boolean | undefined
  multiple?: boolean
}

export default function LocationAutocomplete({ onValueChange, value, autoFocus, multiple = false }: LocationAutocompleteProps) {
  const [options, setOptions] = useState<CityType[]>([])

  const { loading, data, refetch } = useQuery(GET_CITIES, { variables: { q: 'metro manila' } })

  useEffect(() => {
    if(data && !loading) {
      setOptions(data.cities)
    }
  }, [loading, data])

  function handleInputChange(_event: React.SyntheticEvent, query: string) {
    if (query.length > 2) {
      refetch({ q: query })
    }
  }

  return (
    <Autocomplete
      sx={{ 'input': { margin: 0 }}}
      autoFocus={autoFocus}
      disableCloseOnSelect={multiple}
      multiple={multiple}
      value={value}
      onChange={(_event, val) => {
        onValueChange(val)
      }}
      getOptionLabel={(option) => `${option.name}, ${option.provinceName}`}
      isOptionEqualToValue={(option, value) => {
        return option.id === value?.id
      }}
      placeholder="Search your city or province"
      onInputChange={handleInputChange}
      noOptionsText="Location not found"
      options={options}
    />
  )
}
