//@ts-expect-error: This is needed by Rails
import React, { useState } from 'react'
import { DialogContent, DialogTitle, Button, FormControl, FormLabel, Modal, ModalDialog, Option, Select, Box, Typography, FormHelperText } from "@mui/joy"
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_JOBS, UPDATE_JOB } from '../graphql/job-queries'
import { GET_JOB_ESTIMATES } from '../graphql/estimate-queries'
import { fontSizes } from '../layouts/Theme'
import EstimateType from '../types/estimate-type'
import JobType from '../types/job-type'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  job: JobType
  onJobCompleted: (winningBusiness: EstimateType) => void
}
export default function MarkAsCompletedModal({ open, setOpen, job, onJobCompleted }: Props) {
  const { data: { jobEstimates } } = useSuspenseQuery(GET_JOB_ESTIMATES, { variables: { jobId: job.id }})
  const [winningEstimateId, setWinningEstimateId] = useState<string | null>(null)
  const [updateJob, { loading: saving }] = useMutation(UPDATE_JOB, {
    onCompleted: handleCompleted,
    refetchQueries: [{ query: GET_JOBS }]
  })
  const isJobActive = job.status === 'active'

  function handleCompleted() {
    const estimate = jobEstimates.find(({ id }) => id === winningEstimateId)
    onJobCompleted(estimate!)
    setOpen(false)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog sx={{ width: { xs: '80%', md: '30%' }}}>
        {isJobActive &&
          <DialogTitle sx={{ fontSize: fontSizes.large }}>
            Mark the Job as Completed
          </DialogTitle>}
        {isJobActive &&
          <DialogContent>
            <Typography level="body-md">Are you sure? Hindi ka na makaka-tanggap ng estimates sa job na ito.</Typography>
          </DialogContent>}
        {!!jobEstimates.length &&
          <FormControl sx={{ mt: 1, mb: 1 }}>
            <FormLabel>Select Winning Estimate</FormLabel>
            <Select
              slotProps={{ listbox: { placement: 'bottom-start' }}}
              placeholder="Select Business"
              value={winningEstimateId}
              onChange={(_, value) => setWinningEstimateId(value)}
            >
              {jobEstimates.map((estimate) => {
                return <Option key={estimate.id} value={estimate.id}>{estimate.businessUser.businessProfile.name}</Option>
              })}
            </Select>
            <FormHelperText>
              Pwede kang mag bigay ng review sa napili mong business
            </FormHelperText>
          </FormControl>}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          {isJobActive ?
            <Button disabled={saving} onClick={() => {
              updateJob({ variables: { id: job.id, attributes: { status: 'completed', winningEstimateId }} })
            }}>
              Mark as Completed
            </Button> :
            <Button onClick={() => handleCompleted()}>
              Next
            </Button>}
          <Button color="neutral" variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
        </Box>
      </ModalDialog>
    </Modal>
  )
}
