import { Box } from '@mui/joy';
import JobType from '../types/job-type'
import ImagesPreview from './ImagesPreview'
import CategoryIcon from './CategoryIcon'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import CircleIcon from '@mui/icons-material/Circle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Button, Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy'
import { fontSizes, colors } from '../layouts/Theme'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useNavigate } from 'react-router-dom';
import JobTypeBadge from './JobTypeBadge';
dayjs.extend(relativeTime)

interface Props {
  job: JobType
  onCompleted: (job: JobType) => void
}

export default function JobCard({ job, onCompleted }: Props) {
  const navigate = useNavigate()
  const { id, jobType, summary, jobCategory, images, status, createdAt, estimatesCount } = job

  return (
    <Box
      key={id}
      sx={{
        position: 'relative',
        backgroundColor: '#FFF',
        padding: '20px',
        mb: 2,
        borderRadius: '20px'
      }}
    >
      <JobTypeBadge jobType={jobType} />
      <Box sx={{ display: 'flex' }}>
        <CategoryIcon categorySlug={jobCategory.slug} />
        <Box>
          <Box sx={{ fontSize: fontSizes.regular, fontWeight: 600, pr: '7px' }}>{summary}</Box>
          <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2 }}>
            Posted {dayjs(createdAt).fromNow()}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', mt: 2, gap: 1, fontSize: fontSizes.sm }}>
        <Box sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', color: colors.gray3, border: `1px solid ${colors.gray1}`, borderRadius: '16px', pl: '6px', pr: '12px', height: '30px' }}>
          <CircleIcon sx={{ mr: '5px', fontSize: '14px', color: status === 'active' ? colors.green : colors.gray1 }} />{status}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', color: colors.gray3, border: `1px solid ${colors.gray1}`, borderRadius: '16px', pl: '6px', pr: '12px', height: '30px' }}>
          <RequestQuoteIcon sx={{ mr: '5px', fontSize: '20px', color: colors.gray1 }} />Estimate: {estimatesCount}
        </Box>
      </Box>
      <Dropdown>
        <MenuButton sx={{ position: 'absolute', right: '3px', top: '10px' }} slots={{ root: IconButton }} slotProps={{ root: { variant: 'plain', color: 'neutral' }}}>
          <MoreVertIcon />
        </MenuButton>
        <Menu sx={{ width: '150px' }} placement="bottom-end">
          <MenuItem onClick={() => navigate(`/customer/job/${id}/edit`)}>Edit</MenuItem>
        </Menu>
      </Dropdown>
      <ImagesPreview images={images} />
        <Box sx={{ mt: (estimatesCount > 0 || status === 'active') ? 3 : 0, display: 'flex', gap: 1 }}>
          {estimatesCount > 0 && <Button variant="outlined" color="neutral" onClick={() => navigate(`/customer/job/${id}`)}>View Estimates</Button>}
          {status === 'archived' && estimatesCount > 0 &&
            <Button variant="outlined" color="neutral" onClick={(e) => { onCompleted(job); e.stopPropagation()}}>Give Review</Button>}
          {status === 'active' &&
            <Button variant="outlined" color="neutral" onClick={(e) => { onCompleted(job); e.stopPropagation()}}>
              Mark as Completed
            </Button>}
        </Box>
    </Box>
  ) 
}
