import { Box, Grid, Tab, TabList, TabPanel, Tabs, tabClasses } from "@mui/joy"
import CustomerLoginOptions from "../components/CustomerLoginOptions"
import BusinessLoginOptions from "../components/BusinessLoginOptions"
import PersonIcon from '@mui/icons-material/Person'
import HandymanIcon from '@mui/icons-material/Handyman'

export default function LoginPage() {
  return (
    <Grid container justifyContent="center" sx={{ py: 4, px: { xs: 4, md: 10 } }}>
      <Grid xs={12} md={5}>
        <Tabs
          variant="outlined"
          defaultValue={0}
          sx={{ borderRadius: 'lg', overflow: 'auto', border: 0, bgcolor: 'transparent' }}
        >
          <TabList
            disableUnderline
            tabFlex={1}
            sx={{
              p: 0.75,
              gap: 1,
              borderRadius: 'xl',
              bgcolor: 'background.level1',
              fontWeight: 500,
              fontSize: { xs: '14px', md: '16px' },
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: 'sm',
                bgcolor: 'background.surface',
              },
            }}
          >
            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
              <PersonIcon sx={{ fontSize: '20px' }} />Client
            </Tab>
            <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
              <HandymanIcon sx={{ fontSize: '20px', mr: '3px' }} />Business
            </Tab>
          </TabList>
          <TabPanel value={0} sx={{ p: 0, pt: '20px' }}>
            <Box sx={{ bgcolor: 'white', p: '20px', borderRadius: '20px' }}>
              <CustomerLoginOptions />
            </Box>
          </TabPanel>
          <TabPanel value={1} sx={{ p: 0, pt: '20px' }}>
            <Box sx={{ bgcolor: 'white', p: '20px', borderRadius: '20px' }}>
              <BusinessLoginOptions />
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </Grid>
  )
}
