//@ts-expect-error: This is needed by Rails
import React, { useContext } from 'react'
import { LayoutContext } from './LayoutContextProvider'
import { Box, IconButton, Snackbar } from '@mui/joy'
import CloseIcon from '@mui/icons-material/Close'

export default function Alert() {
  const { openAlert, setOpenAlert, alertMessage, alertType } = useContext(LayoutContext)

  return (
    <Snackbar
      variant="solid"
      open={openAlert}
      onClose={() => setOpenAlert(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      color={alertType}
      autoHideDuration={8000}
    >
      <IconButton sx={{ position: 'absolute', right: '3px', top: '3px', '&:hover': { bgcolor: 'transparent' } }} size="sm" onClick={() => setOpenAlert(false)}>
        <CloseIcon sx={{ color: 'white', fontSize: '16px' }} />
      </IconButton>
      <Box sx={{ pr: '25px' }}>{alertMessage}</Box>
    </Snackbar>
  )
}
