import { gql, TypedDocumentNode } from '@apollo/client';
import CustomerType from '../types/customer-type'
import BusinessUserType from '../types/business-user-type'

export const DELETE_CURRENT_CUSTOMER: TypedDocumentNode<{ deleteCustomer: { success: boolean, errors: string[] }}, { confirmationEmail: string }> = gql`
  mutation DeleteCustomer($confirmationEmail: String!) {
    deleteCustomer(input: { confirmationEmail: $confirmationEmail }) {
      success
      errors
    }
  }
`

export const DELETE_CURRENT_BUSINESS_USER: TypedDocumentNode<{ deleteBusinessUser: { success: boolean, errors: string[] }}, { confirmationEmail: string }> = gql`
  mutation DeleteBusinessUser($confirmationEmail: String!) {
    deleteBusinessUser(input: { confirmationEmail: $confirmationEmail }) {
      success
      errors
    }
  }
`

export const UPDATE_CURRENT_CUSTOMER_REFERRAL_CODE: TypedDocumentNode<{ updateCustomerReferralCode: { errors: string[] }}, { referralCode: string }> = gql`
  mutation UpdateCustomerReferralCode($referralCode: String!) {
    updateCustomerReferralCode(input: { referralCode: $referralCode }) {
      errors
    }
  }
`

export const UPDATE_CURRENT_BUSINESS_REFERRAL_CODE: TypedDocumentNode<{ updateBusinessReferralCode: { errors: string[] }}, { referralCode: string }> = gql`
  mutation UpdateBusinessReferralCode($referralCode: String!) {
    updateBusinessReferralCode(input: { referralCode: $referralCode }) {
      errors
    }
  }
`

export const GET_CURRENT_CUSTOMER: TypedDocumentNode<{ currentCustomer: CustomerType }> = gql`
  query CurrentCustomer {
    currentCustomer {
      id
      email
      fullName
      unreadNotificationsCount
      provider
      referralCode
      isAmbassador
      isAdmin
      walletBalance
      discountPercentage
      magicLoginToken
      phoneNumber
      hasUnreadMessage
      lastCity {
        id
        name
        provinceName
        provinceId
      }
    }
  }
`

export const GET_CURRENT_BUSINESS_USER: TypedDocumentNode<{ currentBusinessUser: BusinessUserType }> = gql`
  query CurrentBusinessUser {
    currentBusinessUser {
      id
      email
      fullName
      unreadNotificationsCount
      walletBalance
      provider
      estimateOnFreeJob
      estimateOnPromotedJob
      estimateOnHighValueJob
      referralCode
      discountPercentage
      magicLoginToken
      hasUnreadMessage
      businessProfile {
        id
        completed
        coverageCompleted
      }
    }
  }
`
