import { Box, Typography } from '@mui/joy'
import { RenovationJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function RenovationDetails({ attrs }: { attrs: RenovationJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Property Area for Renovation</Typography>
        <Typography level="title-sm">{attrs.propertyArea}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Floor Area (sqm)</Typography>
        <Typography level="title-sm">{attrs.floorArea}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Existing Condition</Typography>
        <Typography level="title-sm">{attrs.existingCondition}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Type of Finish</Typography>
        <Typography level="title-sm">{attrs.typeOfFinish}</Typography>
      </Box>
    </Box>
  )
}
