import { useMutation, useSuspenseQuery } from "@apollo/client";
import { Box, Button, FormControl, FormLabel, Grid, Input, Modal, ModalDialog, Table, Typography } from "@mui/joy";
import { GET_ADMIN_BUSINESSES, VERIFY_BUSINESS_REFERRAL } from "../../graphql/admin-queries";
import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdminBusinessType } from "../../types/business-user-type";
import { isEmpty } from "lodash";
import { LayoutContext } from "../../layouts/LayoutContextProvider";

function VerifyModal({ open, business, closeModal }: { open: boolean, business: AdminBusinessType, closeModal: () => void }) {
  const [referralCode, setReferralCode] = useState(business.referralCode || '')
  const { showAlert } = useContext(LayoutContext)

  useEffect(() => {
    setReferralCode(business.referralCode || '')
  }, [business])

  const [verifyBusinessReferral, { loading }] = useMutation(VERIFY_BUSINESS_REFERRAL, {
    variables: { businessProfileId: business.id, referralCode: referralCode },
    onCompleted: ({ verifyBusinessReferral: { message, errors }}) => {
      if (errors.length) {
        showAlert(errors.join(', '), 'danger')
      } else {
        showAlert(message)
        closeModal()
      }
    },
    refetchQueries: [GET_ADMIN_BUSINESSES]
  })

  function handleSubmit() {
    if(!isEmpty(referralCode)) {
      verifyBusinessReferral()
    }
  }

  return (
    <Modal open={open} onClose={() => closeModal()} disableRestoreFocus>
      <ModalDialog sx={{ width: { xs: '90%', md: '40%' }}}>
        <Box sx={{ p: 1, mb: 2 }}>
          <Typography level="h4">Verify Business Referral</Typography>
          <Box sx={{ mt: 2 }}>{business.name}</Box>
          <form onSubmit={(e) => { e.preventDefault() ; handleSubmit() }}>
            <FormControl>
              <FormLabel>Referral Code</FormLabel>
              <Input
                required
                autoFocus
                value={referralCode}
                onChange={({ target: { value }}) => setReferralCode(value)}
              />
            </FormControl>
            <FormControl>
              <Button sx={{ mt: 2 }} type="submit" disabled={isEmpty(referralCode) || loading}>Verify</Button>
              <Button sx={{ mt: 1 }} color="neutral" variant="outlined" onClick={() => closeModal()}>Cancel</Button>
            </FormControl>
          </form>
        </Box>
      </ModalDialog>
    </Modal>
  )
}

export default function BusinessVerificationPage() {
  const [query, setQuery] = useState('')
  const [finalQuery, setFinalQuery] = useState<string>()
  const [openModal, setOpenModal] = useState(false)
  const [selectedBusiness, setSelectedBusiness] = useState<AdminBusinessType | null>(null)
  const { data: { adminBusinesses }} = useSuspenseQuery(GET_ADMIN_BUSINESSES, { variables: { q: finalQuery} })

  function handleSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    if (query) {
      setFinalQuery(query)
    }
  }

  function openBusinessVerificationModal(business: AdminBusinessType) {
    setSelectedBusiness(business)
    setOpenModal(true)
  }

  return (
    <Grid container>
      {selectedBusiness && <VerifyModal open={openModal} closeModal={() => setOpenModal(false)} business={selectedBusiness} />}
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>
          Business Verification
        </Typography>
      </Grid>
      <Grid xs={12}>
        <form onSubmit={handleSearch}>
          <FormControl sx={{ mb: 2 }} orientation="horizontal">
            <Input type="text" placeholder="Search business name or email" value={query} onChange={({ target: { value }}) => setQuery(value)} sx={{ width: '100%' }} />
            <Button sx={{ ml: 1 }} type="submit">Search</Button>
            <Button sx={{ ml: 1 }} color="neutral" variant="outlined" onClick={() => { setFinalQuery(''); setQuery('') }}>Clear</Button>
          </FormControl>
        </form>
        <Box sx={{ bgcolor: 'white', borderRadius: '10px', px: 1, overflowX: 'auto' }}>
          <Table aria-label="basic table" sx={{ fontSize: '12px', minWidth: '1000px' }}>
            <thead>
              <tr>
                <th style={{width: '160px', backgroundColor: '#FFFFFF'}}>Email</th>
                <th style={{width: '50px', backgroundColor: '#FFFFFF'}}>Referral</th>
                <th style={{width: '50px', backgroundColor: '#FFFFFF'}}>Verified</th>
                <th style={{width: '50px', backgroundColor: '#FFFFFF'}}>Coverage</th>
                <th style={{width: '100px', backgroundColor: '#FFFFFF'}}>Business Name</th>
                <th style={{width: '150px', backgroundColor: '#FFFFFF'}}>Covered Categories</th>
                <th style={{width: '150px', backgroundColor: '#FFFFFF'}}>Covered Locations</th>
                <th style={{width: '70px', backgroundColor: '#FFFFFF'}}>Date Joined</th>
              </tr>
            </thead>
            <tbody>
              {adminBusinesses.length ?
                adminBusinesses.map((business) => (
                  <tr key={business.id}>
                    <td>{business.email}</td>
                    <td>{business.referralCode}</td>
                    <td>
                      {business.referralVerified ?
                        'Yes' :
                        <Button variant="outlined" color="neutral" onClick={() => openBusinessVerificationModal(business)}>
                          Verify
                        </Button>}
                    </td>
                    <td>{business.coverageCompleted? 'Completed' : 'Not Completed'}</td>
                    <td>{business.name}</td>
                    <td>{business.coveredCategories}</td>
                    <td>{business.coveredLocations}</td>
                    <td>{dayjs(business.createdAt).format('MMM DD, YYYY hh:mmA')}</td>
                  </tr>
                )) : <tr style={{ padding: '10px' }}><td colSpan={3}>No results found</td></tr>}
            </tbody>
          </Table>
        </Box>
      </Grid>
    </Grid>
  )
}
