import { useSuspenseQuery } from "@apollo/client"
import JobCategoryType from "../types/job-category-type"
import { GET_JOB_CATEGORIES } from "../graphql/job-category-queries"
import { Grid } from "@mui/joy"
import CategoryCard from "./CategoryCard"
import { useEffect, useState } from "react"

export const CATEGORY_GROUPS = {
  newHouse: [
    'construction',
    'interior-design',
    'architecture-design',
    'modular-cabinet',
    'finishing-materials',
    'glass-steel-aluminum',
    'furniture',
    'curtain',
    'gardening-and-landscaping',
    'aircon-services',
    'cctv',
    'lighting',
    'smart-devices',
    'door-and-windows',
    'installation-services',
    'gates-and-fences',
    'fire-safety-equipments'
  ],
  improvement: [
    'renovation',
    'modular-cabinet',
    'finishing-materials',
    'glass-steel-aluminum',
    'furniture',
    'curtain',
    'gardening-and-landscaping',
    'water-filtration',
    'solar-panel',
    'cctv',
    'lighting',
    'smart-devices',
    'roofing',
    'installation-services',
    'gates-and-fences',
    'waterproofing',
    'swimming-pool'
  ],
  maintenance: [
    'paint',
    'plumbing',
    'electrical',
    'pest-control',
    'cleaning-services',
    'aircon-services',
    'appliance-repair',
    'roofing',
    'waterproofing',
  ]
}

type JobCategoriesProps = {
  selectedJobCategory: JobCategoryType | null
  categoryGroupFilter?: keyof typeof CATEGORY_GROUPS
  onClick: (jobCategory: JobCategoryType | null) => void
}

export default function JobCategories({ selectedJobCategory, onClick, categoryGroupFilter }: JobCategoriesProps) {
  const { data: { jobCategories }} = useSuspenseQuery(GET_JOB_CATEGORIES)
  const [filteredCategories, setFilteredCategories] = useState(jobCategories)

  useEffect(() => {
    filterJobCategories()
  }, [categoryGroupFilter])

  function filterJobCategories() {
    if(categoryGroupFilter) {
      const filtered = jobCategories.filter((category) => {
        return CATEGORY_GROUPS[categoryGroupFilter].includes(category.slug)
      }) 
      setFilteredCategories(filtered)
    }
  }

  return (
    <Grid container spacing={{ xs: 2, lg: 4 }}>
      {filteredCategories.map((jobCategory) => (
        <Grid xs={4} md={2} key={jobCategory.id}>
          <CategoryCard
            jobCategory={jobCategory}
            selected={jobCategory.id === selectedJobCategory?.id}
            onClick={onClick}
          />
        </Grid>
      ))}
    </Grid>
  )
}
