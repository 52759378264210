//@ts-expect-error: This is needed by Rails
import React, { Suspense, useEffect, useState } from 'react'
import Box from '@mui/joy/Box'
import { Outlet } from 'react-router-dom'
import { colors, colorsV2 } from '../Theme'
import LayoutContextProvider from '../LayoutContextProvider'
import Alert from '../Alert'
import Header from '../Header'
import BusinessUserContextProvider from './BusinessUserContextProvider'
import ErrorFallback from '../../pages/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'
import LoadingScreen from '../LoadingScreen'
import Sidebar from '../Sidebar'
import BusinessMenu from '../../components/BusinessMenu'

export default function BusinessLayout() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LayoutContextProvider>
        <BusinessUserContextProvider>
          <Box sx={{ display: 'flex' }}>
            <Sidebar label="Business">
              <BusinessMenu />
            </Sidebar>
            <Box
              sx={{
                width: '100%',
                paddingTop: { xs: '0', sm: '10px' },
                backgroundColor: colorsV2.background,
              }}
            >
              <Header />
              <Box sx={{ p: { xs: '20px', sm: '30px' }}}>
                <Alert />
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </Box>
            </Box>
          </Box>
        </BusinessUserContextProvider>
      </LayoutContextProvider>
    </ErrorBoundary>
  )
}
