import { Box, Typography } from '@mui/joy'
import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import { colors, fontSizes } from '../layouts/Theme'
import { GENERATE_INVOICE_URL } from '../graphql/invoice-queries'
import { BusinessUserContext } from '../layouts/business/BusinessUserContextProvider'

export interface TopUpSelectorProps {
  setGeneratingInvoice: (invoiceGenerating: boolean) => void,
  redirectUrl: string
}

type InvoiceSku = 'value_250_no_expiry' | 'value_500_no_expiry' | 'regular_load_150' | 'regular_load_300' | 'regular_load_500' | 'regular_load_1000' | 'regular_load_5000'

export default function TopUpSelector({ setGeneratingInvoice, redirectUrl }: TopUpSelectorProps) {
  const { showAlert } = useContext(LayoutContext)
  const { discountPercentage } = useContext(BusinessUserContext)
  const hasDiscount = discountPercentage > 0

  const [generateInvoiceUrl] = useMutation(GENERATE_INVOICE_URL, {
    onCompleted: (({ generateInvoiceUrl: { invoiceUrl, errors }}) => {
      if (!errors.length) {
        window.location.href = invoiceUrl
      } else {
        showAlert("Something went wrong and we're working on it!", 'danger')
      }
    })
  })

  function handleInvoiceSkuClick(invoiceSku: InvoiceSku) {
    setGeneratingInvoice(true)
    generateInvoiceUrl({ variables: { userType: 'business', sku: invoiceSku, redirectUrl }})
  }

  function computeDiscountFor(amount: number) {
    return amount - (amount * (discountPercentage / 100))
  }

  return (
    <Box>
      <Box>
        {hasDiscount &&
          <Box>
            <Typography level="title-md" sx={{ mb: 2, color: colors.green }}>Meron kang {discountPercentage}% discount mula sa Referral Code</Typography>
          </Box>}
        <Typography level="title-lg">Promo Tipid Bundles</Typography>
        <Typography level="body-sm" sx={{ mb: 2, color: colors.gray3 }}>Promo period from Jan 12 to Feb 1</Typography>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          <Box
            sx={{
              p: 2, border: `1px solid ${colors.gray2}`, borderRadius: '8px',
              width: { xs: '100%', md: '50%' },
              '&:hover': { cursor: 'pointer' }
            }}
            onClick={() => handleInvoiceSkuClick('value_250_no_expiry')}
          >
            <Typography level="title-md">Value 250</Typography>
            <Typography level="body-sm" sx={{ mb: 1, color: colors.gray3 }}>
            {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱250</span> ₱{computeDiscountFor(250)} | No Expiration</span> : <span>Price: ₱250 | No Expiration</span>}
            </Typography>
            <ul style={{ fontSize: fontSizes.sm, lineHeight: '1.1em' }}>
              <li style={{ marginBottom: '8px' }}>10 estimates on Free Job Posts</li>
              <li style={{ marginBottom: '8px' }}>1 estimate on Promoted Job Post</li>
              <li>Save as much as ₱350</li>
            </ul>
          </Box>
          <Box
            sx={{
              p: 2, border: `1px solid ${colors.gray2}`, borderRadius: '8px',
              width: { xs: '100%', md: '50%' },
              '&:hover': { cursor: 'pointer' }
            }}
            onClick={() => handleInvoiceSkuClick('value_500_no_expiry')}
          >
            <Typography level="title-md">Value 500</Typography>
            <Typography level="body-sm" sx={{ mb: 1, color: colors.gray3 }}>
              {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱500</span> ₱{computeDiscountFor(500)} | No Expiration</span> : <span>Price: ₱500 | No Expiration</span>}
            </Typography>
            <ul style={{ fontSize: fontSizes.sm, lineHeight: '1.1em' }}>
              <li style={{ marginBottom: '8px' }}>22 estimates on Free Job Posts</li>
              <li style={{ marginBottom: '8px' }}>3 estimate on Promoted Job Post</li>
              <li>Save as much as ₱900</li>
            </ul>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography level="title-md">Regular Load</Typography>
        <Typography level="body-sm" sx={{ mb: 2, color: colors.gray3 }}>All regular loads ay walang expiration</Typography>
        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'row', flexWrap: 'wrap' }}>
          <Box
            sx={{
              p: 2, border: `1px solid ${colors.gray2}`, borderRadius: '8px',
              width: { xs: '100%', md: '25%' },
              '&:hover': { cursor: 'pointer' }
            }}
            onClick={() => handleInvoiceSkuClick('regular_load_150')}
          >
            <Typography level="title-md">₱150</Typography>
            <Typography level="body-sm" sx={{ color: colors.gray3 }}>
              {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱150</span> ₱{computeDiscountFor(150)}</span> : <span>Price: ₱150</span>}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2, border: `1px solid ${colors.gray2}`, borderRadius: '8px',
              width: { xs: '100%', md: '25%' },
              '&:hover': { cursor: 'pointer' }
            }}
            onClick={() => handleInvoiceSkuClick('regular_load_300')}
          >
            <Typography level="title-md">₱300</Typography>
            <Typography level="body-sm" sx={{ color: colors.gray3 }}>
              {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱300</span> ₱{computeDiscountFor(300)}</span> : <span>Price: ₱300</span>}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2, border: `1px solid ${colors.gray2}`, borderRadius: '8px',
              width: { xs: '100%', md: '25%' },
              '&:hover': { cursor: 'pointer' }
            }}
            onClick={() => handleInvoiceSkuClick('regular_load_500')}
          >
            <Typography level="title-md">₱500</Typography>
            <Typography level="body-sm" sx={{ color: colors.gray3 }}>
              {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱500</span> ₱{computeDiscountFor(500)}</span> : <span>Price: ₱500</span>}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2, border: `1px solid ${colors.gray2}`, borderRadius: '8px',
              width: { xs: '100%', md: '25%' },
              '&:hover': { cursor: 'pointer' }
            }}
            onClick={() => handleInvoiceSkuClick('regular_load_1000')}
          >
            <Typography level="title-md">₱1,000</Typography>
            <Typography level="body-sm" sx={{ color: colors.gray3 }}>
              {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱1,000</span> ₱{computeDiscountFor(1000)}</span> : <span>Price: ₱1,000</span>}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2, border: `1px solid ${colors.gray2}`, borderRadius: '8px',
              width: { xs: '100%', md: '25%' },
              '&:hover': { cursor: 'pointer' }
            }}
            onClick={() => handleInvoiceSkuClick('regular_load_5000')}
          >
            <Typography level="title-md">₱5,000</Typography>
            <Typography level="body-sm" sx={{ color: colors.gray3 }}>
              {hasDiscount ? <span>Price: <span style={{ textDecorationLine: 'line-through' }}>₱5,000</span> ₱{computeDiscountFor(5000)}</span> : <span>Price: ₱5,000</span>}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
