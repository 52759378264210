import { Box, FormControl, FormLabel, Input, Select, Option } from "@mui/joy";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { CurtainJobAttributes, JobAttributes } from "../../types/job-type";

export default function CurtainForm({ register, control, attrs }: {
  register: UseFormRegister<JobAttributes>
  control: Control<JobAttributes>
  attrs: CurtainJobAttributes
}) {
  return (
    <Box sx={{ mt: 1, width: { xs: '100%', md: '50%' }}}>
      <FormControl>
        <FormLabel>Curtain Type</FormLabel>
        <Controller
          name="categoryAttrsValues.curtainType"
          defaultValue={attrs?.curtainType}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              onChange={(_, newValue) => onChange(newValue) }
              value={value}
              placeholder="Mamili ng klase ng curtain"
            >
              <Option value="Classic">Classic</Option>
              <Option value="Korean Blinds">Korean Blinds</Option>
              <Option value="Contemporary">Contemporary</Option>
              <Option value="Modern">Modern</Option>
              <Option value="Vintage">Vintage</Option>
              <Option value="Sheer Only">Sheer Only</Option>
              <Option value="Other">Other</Option>
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ mt: 2 }}>
        <FormLabel>Number of Windows</FormLabel>
        <Input {...register('categoryAttrsValues.numberOfWindows')} defaultValue={attrs?.numberOfWindows} required type="number" placeholder="E.g. 5" />
      </FormControl>
    </Box>
  )
}
