import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '../pages/ErrorFallback'
import LayoutContextProvider from './LayoutContextProvider'
import { Box, Button, Grid, Link } from '@mui/joy'
import Alert from './Alert'
import { Suspense } from 'react'
import { Outlet, Link as NavLink, useNavigate } from 'react-router-dom'
import LoadingScreen from './LoadingScreen'
import { colors, colorsV2 } from './Theme'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'

export default function PublicLayout() {
  const navigate = useNavigate()
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LayoutContextProvider>
        <Box
          sx={{
            position: 'sticky', zIndex: 99, top: 0, borderBottom: `1px solid ${colorsV2.gray3}`,
            display: 'flex', flexDirection: 'row', alignItems: 'center',
            justifyContent: 'space-between', bgcolor: 'white', px: 2, py: '12px'
          }}
        >
          <Box></Box>
          <Link sx={{ ml: '25px' }} component={NavLink} to="/">
            <img src="/logo.png" width="120px" />
          </Link>
          <Button variant="outlined" color="neutral" onClick={() => navigate('/login')}>
            Login
          </Button>
        </Box>
        <Box sx={{ minHeight: '100vh', bgcolor: colorsV2.background }}>
          <Alert />
          <Grid container>
            <Grid xs={12}>
              <Suspense fallback={<LoadingScreen />}>
                <Outlet />
              </Suspense>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ position: 'fixed', visibility: 'hidden', bottom: '15px', right: '15px', zIndex: '1000' }}>
          <Link
            sx={{ textDecoration: 'none', width: '40px', height: '40px', bgcolor: colors.primary, display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)' }}
            href="https://m.me/315240091675958" target="_blank"
          >
            <ContactSupportIcon sx={{ fontSize: '25px', color: 'white' }}/>
          </Link>
        </Box>
      </LayoutContextProvider>
    </ErrorBoundary>
  )
}
