import React, { useContext, useEffect, useState } from 'react'
import { Button, Grid, FormControl, FormLabel, FormHelperText, Input, Textarea, Select, Option, Box } from '@mui/joy'
import NumericFormatAdapter from '../components/NumericFormatAdapter'
import { Controller, useForm } from 'react-hook-form'
import EstimateType, { BusinessAvailability, EstimateAttributes } from '../types/estimate-type'
import { useMutation } from '@apollo/client'
import { CREATE_ESTIMATE, GET_ESTIMATE_FOR_JOB, UPDATE_ESTIMATE } from '../graphql/estimate-queries'
import JobType from '../types/job-type'
import { colors, fontSizes } from '../layouts/Theme'
import { formatAvailability } from './DisplayAvailability'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import { GET_CURRENT_BUSINESS_USER } from '../graphql/user-queries'
import { BusinessUserContext } from '../layouts/business/BusinessUserContextProvider'
import { NumericFormat } from 'react-number-format'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_LEADS } from '../graphql/job-queries'
import PaymentRedirectNotice from './PaymentRedirectNotice'
import TopUpSelectorModal from './TopUpSelectorModal'

export default function SendQuoteForm({ job, estimate, onCompleted, onClose }: { job: JobType, estimate?: EstimateType, onCompleted: () => void, onClose: () => void }) {
  const navigate = useNavigate()
  const businessUser = useContext(BusinessUserContext)
  const { showAlert } = useContext(LayoutContext)
  const { estimateOnFreeJob, estimateOnPromotedJob, estimateOnHighValueJob } = businessUser
  const location = useLocation()
  const currentUrl = `${window.location.origin}${location.pathname}`
  const [generatingInvoice, setGeneratingInvoice] = useState(false)
  const [topUpModalOpen, setTopUpModalOpen] = useState(false)
  const canEstimateOnFreeJob = estimateOnFreeJob > 0 || businessUser.walletBalance >= job.price
  const canEstimateOnPromotedJob = estimateOnPromotedJob > 0 && businessUser.walletBalance >= job.price
  const canEstimateOnHighValueJob = estimateOnHighValueJob > 0 && businessUser.walletBalance >= job.price
  const topUpRequired = !canEstimateOnFreeJob && !canEstimateOnPromotedJob && !canEstimateOnHighValueJob

  const draftEstimateJson = JSON.parse(localStorage.getItem('hm:draftEstimate') || '{}')
  let draftEstimate: EstimateType | null = null
  if (draftEstimateJson && draftEstimateJson.jobId === job.id) {
    draftEstimate = draftEstimateJson as EstimateType
  }

  const { register, handleSubmit, control, formState: { errors } } = useForm<EstimateAttributes>({
    defaultValues: {
      details: estimate?.details || draftEstimate?.details
    }
  })
  const [createEstimate, { loading: formSubmitting }] = useMutation(CREATE_ESTIMATE, {
    refetchQueries: [
      { query: GET_CURRENT_BUSINESS_USER },
      { query: GET_LEADS },
      { query: GET_ESTIMATE_FOR_JOB, variables: { uuid: job.uuid }}
    ],
    onCompleted: ({ createEstimate: { errors }}) => {
      if (errors.length) {
        showAlert(errors.join('\n\n'), 'danger')
      } else {
        onCompleted()
      }
    }
  })
  const [updateEstimate, { loading: formUpdating }] = useMutation(UPDATE_ESTIMATE, { onCompleted: () => showAlert("Your estimate has been updated") })

  function onSubmit(estimateAttrs: EstimateAttributes) {
    if (topUpRequired) {
      setTopUpModalOpen(true)
      localStorage.setItem('hm:draftEstimate', JSON.stringify({ ...estimateAttrs, jobId: job.id }))
      return
    }

    localStorage.removeItem('hm:draftEstimate')
    if (estimate) {
      updateEstimate({ variables: { id: estimate.id, attributes: estimateAttrs as Omit<EstimateAttributes, 'jobId'> }})
    } else {
      createEstimate({ variables: { attributes: { ...estimateAttrs, jobId: job.id } } })
    }
  }

  if (!businessUser.businessProfile?.completed) {
    return (
      <Box sx={{ fontSize: fontSizes.sm, color: colors.gray3, display: 'flex' }}>
        <Box>
          <p>You need to complete your business profile first before you can send estimate</p>
          <Button sx={{ mb: 1 }} variant="outlined" color="neutral" onClick={() => navigate('/business/profile')}>Complete Profile</Button>
        </Box>
      </Box>
    )
  }

  function RegularPriceInfo() {
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <NumericFormat thousandSeparator="," displayType="text" prefix="Price: ₱" value={job.price} />
        <NumericFormat thousandSeparator="," displayType="text" prefix="Wallet: ₱" value={businessUser.walletBalance} />
      </Box>
    )
  }

  if (generatingInvoice) {
    return <PaymentRedirectNotice />
  }

  return (
    <>
      <TopUpSelectorModal
        title="Hindi Sapat ang Iyong Load"
        description="Mamili ng Bundle or Load sa baba upang ma send mo ang estimate na ito"
        open={topUpModalOpen}
        setOpen={setTopUpModalOpen}
        setGeneratingInvoice={setGeneratingInvoice}
        redirectUrl={currentUrl}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid xs={12} sm={6} md={4}>
            <FormControl>
              <FormLabel required>Lower range</FormLabel>
              <Controller
                name="estimateMin"
                defaultValue={estimate?.estimateMin || draftEstimate?.estimateMin}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    error={!!errors.estimateMin}
                    startDecorator="₱"
                    value={value}
                    onChange={(e) => onChange(+e.target.value)}
                    slotProps={{
                      input: {
                        component: NumericFormatAdapter,
                      },
                    }}
                    placeholder="Enter price"
                  />
                )}
              />
              {errors.estimateMin && <FormHelperText sx={{ color: colors.red }}>This field is required</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <FormControl>
              <FormLabel>Higher range</FormLabel>
              <Controller
                name="estimateMax"
                defaultValue={estimate?.estimateMax || draftEstimate?.estimateMax}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    value={value}
                    startDecorator="₱"
                    onChange={(e) => onChange(+e.target.value)}
                    slotProps={{
                      input: {
                        component: NumericFormatAdapter,
                      },
                    }}
                    placeholder="Enter price"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <FormControl>
              <FormLabel>Timeline</FormLabel>
              <Controller
                name="availability"
                defaultValue={estimate?.availability || draftEstimate?.availability || BusinessAvailability.Immediately}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={(_event: React.SyntheticEvent | null, newValue: BusinessAvailability | null) => onChange(newValue)}
                    defaultValue={BusinessAvailability.Immediately}
                  >
                    <Option value={BusinessAvailability.Immediately}>{formatAvailability(BusinessAvailability.Immediately)}</Option>
                    <Option value={BusinessAvailability.Weeks}>{formatAvailability(BusinessAvailability.Weeks)}</Option>
                    <Option value={BusinessAvailability.Months}>{formatAvailability(BusinessAvailability.Months)}</Option>
                    <Option value={BusinessAvailability.ScopeBased}>{formatAvailability(BusinessAvailability.ScopeBased)}</Option>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid sm={6}/>
          <Grid xs={12}>
            <FormControl>
              <FormLabel>Details</FormLabel>
              <Textarea {...register("details")} placeholder="Give more details to your estimate, scope of work, and availability" minRows={6}/>
            </FormControl>
          </Grid>
          {!estimate &&<Grid xs={12}>
            <Box sx={{ display: 'flex', fontSize: fontSizes.sm, color: colors.gray3, gap: 2, my: 1 }}>
              {job.jobType === 'free' && estimateOnFreeJob > 0 && <Box>Price: 1 Estimate on Free Job</Box>}
              {job.jobType === 'free' && estimateOnFreeJob <= 0 && <RegularPriceInfo />}

              {job.jobType === 'promoted' && estimateOnPromotedJob > 0 && <Box>Price: 1 Estimate on Promoted Job</Box>}
              {job.jobType === 'promoted' && estimateOnPromotedJob <= 0 && <RegularPriceInfo />}

              {job.jobType === 'high_value' && estimateOnHighValueJob > 0 && <Box>Price: 1 Estimate on High Value Job</Box>}
              {job.jobType === 'high_value' && estimateOnHighValueJob <= 0 && <RegularPriceInfo />}
            </Box>
          </Grid>}
          <Grid xs={12} sx={{ display: 'flex', mt: estimate ? 2 : 0 }}>
            <Button onClick={handleSubmit(onSubmit)} sx={{ mr: '10px' }} disabled={formSubmitting || formUpdating}>{estimate ? 'Update' : 'Send'} Estimate</Button>
            <Button onClick={() => onClose()} color="neutral" variant="outlined">Cancel</Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
