import { Box, Button, Modal, ModalDialog, Typography } from '@mui/joy'

export default function DownloadAppModal(
  { openMobileNotice, title = 'Download the HomeMaster App', description, closeMobileNotice }:
  { openMobileNotice: boolean, title?: string, description: string , closeMobileNotice: () => void }
) {
  return (
    <Modal open={openMobileNotice} onClose={() => closeMobileNotice()}>
      <ModalDialog sx={{ width: { xs: '90%', md: '40%' }}}>
        <Box sx={{ p: 1, mb: 2, textAlign: 'center' }}>
          <Typography level="h4">{title}</Typography>
          <Box sx={{ mt: 2 }}>{description}</Box>
          <Box sx={{ mt: 2, display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <a href="https://play.google.com/store/apps/details?id=ph.homemaster.android"><img src="/google-play-badge.png" width="150px" /></a>
            <a href="https://apps.apple.com/us/app/homemaster-ph/id6737192957"><img src="/app-store-badge.png" width="135px" /></a>
          </Box>
        </Box>
        <Button color="neutral" variant="outlined" onClick={() => closeMobileNotice()}>Close</Button>
      </ModalDialog>
    </Modal>
  )
}
