//@ts-expect-error: This is needed by Rails
import React, { useContext } from 'react'
import { Grid, IconButton, Typography } from '@mui/joy'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { GET_JOB, GET_JOBS, UPDATE_JOB } from '../../graphql/job-queries'
import JobForm from '../../components/JobForm'
import { UpdateJobAttributes } from '../../types/job-type'
import { LayoutContext } from '../../layouts/LayoutContextProvider'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

export default function EditJobPage() {
  const { jobId } = useParams()
  const { data: { job } } = useSuspenseQuery(GET_JOB, { variables: { id: jobId! } })
  const { showAlert } = useContext(LayoutContext)
  const [updateJob, { loading: saving }] = useMutation(UPDATE_JOB, {
    refetchQueries: [{ query: GET_JOBS }]
  })
  const navigate = useNavigate()

  function onSubmit(attributes: UpdateJobAttributes) {
    updateJob({
      variables: { id: jobId!, attributes },
      onCompleted: () => {
        showAlert("Your job post has been updated successfully")
      },
    })
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <IconButton
          sx={{ mb: 2, pl: '5px', pr: '10px' }}
          onClick={() => navigate('/customer/jobs')}
        >
          <KeyboardArrowLeftIcon sx={{ mr: 0 }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12}>
        <Typography level="h1" sx={{ mb: 2 }}>Edit Job</Typography>
      </Grid>
      <Grid xs={12} lg={7}>
        <JobForm
          onSubmit={onSubmit}
          saving={saving}
          submitLabel="Save"
          jobCategory={job.jobCategory}
          city={job.city}
          job={job}
        />
      </Grid>
    </Grid>
  )
}
