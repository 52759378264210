//@ts-expect-error: This is needed by Rails
import React, { useEffect } from 'react'
import { Grid, Typography, Box, Button, IconButton } from '@mui/joy'
import { GET_JOB } from '../../graphql/job-queries'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { colors, fontSizes } from '../../layouts/Theme'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
import DisplayMinMax from '../../components/DisplayMinMax'
import DisplayAvailability from '../../components/DisplayAvailability'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import BusinessHeader from '../../components/BusinessHeader'
import { MARK_ESTIMATE_AS_SEEN } from '../../graphql/estimate-queries'

export default function JobPage() {
  const navigate = useNavigate()
  const { jobId } = useParams()
  const { data: { job } } = useSuspenseQuery(GET_JOB, { variables: { id: jobId || '' } })
  const { estimates, estimatesCount } = job

  const [markEstimateAsSeen] = useMutation(MARK_ESTIMATE_AS_SEEN)

  useEffect(() => {
    estimates.forEach(({ id, seenAt }) => {
      if (!seenAt) {
        markEstimateAsSeen({ variables: { id }})
      }
    })
  }, [])

  return (
    <Grid container>
      <Grid xs={12}>
        <IconButton
          sx={{ mb: 2, pl: '5px', pr: '10px' }}
          onClick={() => navigate('/customer/jobs')}>
          <KeyboardArrowLeftIcon sx={{ mr: 0 }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12}>
        <Typography level="h2" sx={{ mb: 2 }}>Estimates Received ({estimatesCount})</Typography>
      </Grid>
      {estimates.length ?
        <>
          {estimates.map(({ id, createdAt, availability, estimateMin, estimateMax, businessUser: { id: businessUserId, businessProfile }, businessReviews }) => (
            <Grid xs={12} lg={7} key={id}> 
              <Box sx={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px', mb: 2 }}>
                <BusinessHeader reviews={businessReviews || []} businessProfile={businessProfile} jobId={jobId!} businessUserId={businessUserId} />
                <Box sx={{ fontSize: fontSizes.sm, color: colors.gray2, mt: '-2px' }}>
                  Received {dayjs(createdAt).fromNow()}
                </Box>
                <Box sx={{ display: 'flex', mt: 2, gap: 1, flexWrap: 'wrap' }}>
                  <DisplayMinMax valueMin={estimateMin} valueMax={estimateMax} />
                  <DisplayAvailability availability={availability} /> 
                </Box>
                <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                  <Button color="neutral" variant="outlined" onClick={() => navigate(`/customer/job/${jobId}/estimate/${id}`)}>View Details</Button>
                  <Button color="neutral" variant="outlined" onClick={() => navigate(`/customer/messages/${id}`)}>Message</Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </> :
        <Box sx={{ mt: '10px' }}>There is no estimates yet.</Box>}
    </Grid>
  )
}
