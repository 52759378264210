//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box } from '@mui/joy'
import { colors } from '../layouts/Theme'

export default function NotificationBadge({ count }: { count: number }) {
  if (count === 0) {
    return
  }

  return (
    <Box
      sx={{
        fontSize: '10px',
        padding: '3px 6px',
        textAlign: 'center',
        borderRadius: '4px',
        backgroundColor: colors.red,
        color: 'white'
      }}
    >
      {count}
    </Box>
  )
}
