import { Box, Button, IconButton, Link, Textarea } from '@mui/joy'
import MessageType from '../types/message-type'
import { colors, fontSizes } from '../layouts/Theme'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useContext, useEffect, useRef, useState } from 'react'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import { useMutation } from '@apollo/client/react'
import { CREATE_MESSAGE_AS_BUSINESS, CREATE_MESSAGE_AS_CUSTOMER, READ_MESSAGES_AS_BUSINESS, READ_MESSAGES_AS_CUSTOMER } from '../graphql/messages-queries'
import { CustomerContext } from '../layouts/customer/CustomerContextProvider'
import { BusinessUserContext } from '../layouts/business/BusinessUserContextProvider'
import { isEmpty } from 'lodash'
dayjs.extend(relativeTime)
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import DisplayMinMax from './DisplayMinMax'
import EstimateType from '../types/estimate-type'
import Avatar from './Avatar'
import { useCable } from '../hooks/ActionCableContext'

interface Props {
  messageHistory: MessageType[]
  conversationId: string
  title: string
  subtitle: string
  estimate: EstimateType
  senderType: 'Customer' | 'BusinessUser'
}

type ChatMessage = Pick<MessageType, 'id' | 'content' | 'senderId' | 'senderType' | 'createdAt'>

export default function MessageWidget({ messageHistory, conversationId, title, subtitle, estimate, senderType }: Props) {
  const cable = useCable()
  const navigate = useNavigate()
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState<ChatMessage[]>(messageHistory)
  const { showAlert } = useContext(LayoutContext)
  const currentBusinessUser = useContext(BusinessUserContext)
  const currentCustomer = useContext(CustomerContext)
  const currentUser = isEmpty(currentCustomer) ? currentBusinessUser : currentCustomer
  const [createMessageAsCustomer, { loading: sendingAsCustomer }] = useMutation(CREATE_MESSAGE_AS_CUSTOMER, {
    onCompleted: ({ createMessageAsCustomer: { errors, message }}) => {
      if(errors.length) {
        showAlert(errors.join(', '), 'danger')
      } else {
        setMessage('')
        setMessages([...messages, message])
      }
    }
  })
  const [readMessagesAsCustomer] = useMutation(READ_MESSAGES_AS_CUSTOMER, {
    onCompleted: ({ readMessagesAsCustomer: { errors }}) => {
      if(errors.length) {
        showAlert(errors.join(', '), 'danger')
      }
    }
  })

  const backButtonPath = senderType === 'Customer' ? '/customer/messages' : '/business/messages'
  const estimatePath = senderType === 'Customer' ? `/customer/job/${estimate.job.id}/estimate/${estimate.id}?ref=messages` : `/business/leads/${estimate.job.uuid}?ref=messages`
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [createMessageAsBusiness, { loading: sendingAsBusiness }] = useMutation(CREATE_MESSAGE_AS_BUSINESS, {
    onCompleted: ({ createMessageAsBusiness: { errors, message }}) => {
      if(errors.length) {
        showAlert(errors.join(', '), 'danger')
      } else {
        setMessage('')
        setMessages([...messages, message])
      }
    }
  })
  const [readMessagesAsBusiness] = useMutation(READ_MESSAGES_AS_BUSINESS, {
    onCompleted: ({ readMessagesAsBusiness: { errors }}) => {
      if(errors.length) {
        showAlert(errors.join(', '), 'danger')
      }
    }
  })

  const sending = sendingAsCustomer || sendingAsBusiness

  useEffect(() => {
    const channel = cable.subscriptions.create(
      { channel: "ChatChannel", conversation_id: conversationId },
      {
        connected() {
          // provision to add connected indicator
        },
        disconnected() {
          // provision to add discconnected indicator
        },
        received(newMessage: ChatMessage) {
          if (senderType !== newMessage.senderType && currentUser.id !== newMessage.senderId) {
            setMessages((prev) => [...prev, newMessage])
            if (senderType === 'Customer') {
              readMessagesAsCustomer({ variables: { estimateId: conversationId, timestamp: newMessage.createdAt }})
            } else {
              readMessagesAsBusiness({ variables: { estimateId: conversationId, timestamp: newMessage.createdAt }})
            }
          }
        },
      }
    );

    return () => {
      channel.unsubscribe()
    };
  }, [])

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages]); // Re-run whenever messages change

  function sendMessage() {
    if (senderType === 'Customer') {
      createMessageAsCustomer({ variables: { content: message, estimateId: conversationId }})
    } else {
      createMessageAsBusiness({ variables: { content: message, estimateId: conversationId }})
    }
  }

  function ChatAvatar() {
    const businessLogoUrl = estimate.businessUser.businessProfile.logoUrl

    if (senderType === 'BusinessUser' || !businessLogoUrl) {
      return <Avatar userInitial={title[0]} />
    } else {
      return <Avatar avatarUrl={businessLogoUrl} />
    }
  }

  return (

    <Box sx={{ mb: 2, display: 'flex', bgcolor: 'white', borderRadius: '15px', flexDirection: 'column' }}>
      <Box sx={{ p: '10px 20px 10px 0px', display: 'flex' }}>
        <Box>
          <IconButton sx={{ px: '5px' }} onClick={() => navigate(backButtonPath)}>
            <KeyboardArrowLeftIcon sx={{ mr: 0 }} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mr: 1 }}>
            <ChatAvatar />
          </Box>
          <Box>
            <Box sx={{ fontWeight: 500 }}>{title}</Box>
            <Box sx={{ fontSize: '12px', color: colors.gray3, mt: '-3px' }}>{subtitle}</Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        bgcolor: colors.background,
        display: 'flex',
        color: 'white',
        fontSize: '14px',
        justifyContent: 'space-between',
        pl: '10px',
        pr: '15px',
        boxShadow: '0px 10px 15px -5px rgba(0, 0, 0, 0.3)',
        'svg': { color: colors.gray3 }
      }}>
        <DisplayMinMax withBorder={false} valueMin={estimate.estimateMin} valueMax={estimate.estimateMax} />
        <Link component={RouterLink} to={estimatePath} sx={{ color: colors.gray3, fontSize: fontSizes.sm, textDecoration: 'none' }}>View Estimate</Link>
      </Box>
      <Box ref={chatContainerRef} sx={{ p: '20px', display: 'flex', gap: 1, flexDirection: 'column', height: 'calc(100vh - 295px)', overflow: 'scroll' }}>
        {!!messages.length ?
          messages.map(({ id, content, createdAt, senderId }) => {
            const sentByCurrentUser = senderId === currentUser.id
            return (
              <Box
                key={id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: sentByCurrentUser ? 'right' : 'left'
                }}
              >
                {!sentByCurrentUser && <Box sx={{ mr: 1 }}><ChatAvatar /></Box>}
                <Box
                  sx={{
                    p: '7px 15px', fontSize: '14px',
                    whiteSpace: 'pre-wrap', width: 'fit-content',
                    textAlign: sentByCurrentUser ? 'right' : 'left',
                    borderRadius: sentByCurrentUser ? '15px 15px 3px 15px' : '15px 15px 15px 3px',
                    color: sentByCurrentUser ? 'white' : colors.fontColor,
                    bgcolor: sentByCurrentUser ? colors.gray3 : colors.gray1
                  }}
                >
                  <Box>{content}</Box>
                  <Box sx={{ fontSize: '10px', color: colors.gray2, mt: '-3px' }}>{dayjs(createdAt).fromNow()}</Box>
                </Box>
              </Box>
            )
          }) :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', p: '20px' }}>
            <Box sx={{ fontSize: '20px', color: colors.gray2, textAlign: 'center' }}>
              Ang iyong name ay makikita ng business na ito sa oras na mag send ka ng message
            </Box>
          </Box>
        }
      </Box>
      <Box sx={{ borderTop: '1px solid var(--joy-palette-neutral-200)', display: 'flex', p: '20px', alignItems: 'center', gap: 1 }}>
        <Textarea
          sx={{ width: '100%', '&.Mui-focused': { borderColor: 'var(--joy-palette-neutral-300)' } }}
          autoFocus
          minRows={1}
          value={message}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
              event.preventDefault()
              sendMessage()
            }
          }}
          onChange={({ target: { value }}) => setMessage(value)} />
        <Button
          sx={{ width: 'fit-content' }}
          disabled={!message.length || sending}
          onClick={() => sendMessage()}>
          {sending ? 'Sending' : 'Send'}
        </Button>
      </Box>
    </Box>
  )
}
