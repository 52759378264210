import { gql, TypedDocumentNode } from '@apollo/client'
import JobType, { JobAttributes, UpdateJobAttributes } from '../types/job-type'

export const ACTIVATE_TEMPORARY_JOB: TypedDocumentNode<{ activateTemporaryJob: { errors: string[], job: JobType }}, { id: string, jobType: JobType['jobType'] }> = gql`
  mutation ActivateTemporaryJob($id: String!, $jobType: String!) {
    activateTemporaryJob(input: { id: $id, jobType: $jobType }) {
      errors
      job {
        id
      }
    }
  }
`

export const GET_TEMPORARY_JOB: TypedDocumentNode<{ temporaryJob: JobType }> = gql`
  query GetTemporaryJob {
    temporaryJob {
      id
      jobType
    }
  }
`

export const GET_JOB: TypedDocumentNode<{ job: JobType }, { id: string }> = gql`
  query GetJob($id: ID!) {
    job(id: $id) {
      id
      jobType
      jobCategory {
        id
        name
        slug
      }
      city {
        id
        name
      }
      summary
      budgetMin
      budgetMax
      timeline
      description
      status
      categoryAttrsValues
      shareContact
      images {
        id
        url
        thumbnail
        width
        height
      }
      estimatesCount
      estimates {
        id
        estimateMin
        estimateMax
        availability
        details
        businessUser {
          id
          businessProfile {
            name
            address
            yearEstablished
            logoUrl
          }
        }
        businessReviews {
          id
          rating
          description
          createdAt
        }
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`

export const CREATE_JOB: TypedDocumentNode<{ createJob: { errors: string[], job: JobType }}, { attributes: JobAttributes }> = gql`
  mutation CreateJob($attributes: JobAttributes!) {
    createJob(input: { attributes: $attributes }) {
      errors
      job {
        id
        jobCategory {
          id
          name
          slug
        }
        city {
          id
          name
        }
        summary
        budgetMin
        budgetMax
        timeline
        description
        status
        bumpedAt
        shareContact
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_JOBS: TypedDocumentNode<{ jobs: JobType[] }, { status: string }> = gql`
  query GetJobs($status: String) {
    jobs(status: $status) {
      id
      jobType
      jobCategory {
        id
        name
        slug
      }
      city {
        id
        name
      }
      images {
        id
        url
        width
        height
        thumbnail
      }
      estimatesCount
      summary
      status
      budgetMin
      budgetMax
      timeline
      description
      categoryAttrsValues
      bumpedAt
      shareContact
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_JOB: TypedDocumentNode<{ errors: string[], job: JobType }, { id: string, attributes: UpdateJobAttributes }> = gql`
  mutation UpdateJob($id: ID!, $attributes: UpdateJobAttributes!) {
    updateJob(input: { id: $id, attributes: $attributes }) {
      errors
      job {
        id
        budgetMin
        budgetMax
        timeline
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_LEAD: TypedDocumentNode<{ lead: JobType }, { uuid: string }> = gql`
  query GetLead($uuid: ID!) {
    lead(uuid: $uuid) {
      id
      jobType
      price
      uuid
      jobCategory {
        id
        name
        slug
        price
      }
      city {
        id
        name
      }
      budgetMin
      budgetMax
      timeline
      description
      status
      summary
      categoryAttrsValues
      shareContact
      estimatesCount
      images {
        id
        url
        thumbnail
        width
        height
      }
      createdAt
    }
  }
`

export const GET_LEADS: TypedDocumentNode<{ leads: JobType[] }> = gql`
  query GetLeads {
    leads {
      id
      jobType
      price
      uuid
      jobCategory {
        id
        name
        slug
        price
      }
      city {
        id
        name
      }
      summary
      images {
        id
        url
        thumbnail
        width
        height
      }
      budgetMin
      budgetMax
      timeline
      description
      estimatesCount
      shareContact
      createdAt
      updatedAt
    }
  }
`
