import JobCategoryType from './job-category-type'
import CityType from './city-type'
import EstimateType from './estimate-type'
import CustomerType from './customer-type'
import ImageType from './image-type'

export type CategoryAttributesUnion = ConstructionJobAttributes | AirconJobAttributes | PestControlJobAttributes | ModularCabinetJobAttributes | RenovationJobAttributes | CleaningJobAttributes | ElectricalJobAttributes | PlumbingJobAttributes | InteriorDesignJobAttributes | ArchitectureJobAttributes | GatesAndFencesJobAttributes | FurnitureJobAttributes | CurtainJobAttributes

export interface CurtainJobAttributes {
  curtainType: string
  numberOfWindows: number
}

export interface FurnitureJobAttributes {
  furnitureType: string
}

export interface GatesAndFencesJobAttributes {
  serviceType: string
  gateWidth: number
  gateHeight: number
  fenceWidth: number
  fenceHeight: number
}

export interface ArchitectureJobAttributes {
  serviceType: string
  stylePreference: string
  floorArea: number
  specialRequirement: string
}

export interface InteriorDesignJobAttributes {
  serviceType: string
  stylePreference: string
  floorArea: number
}

export interface PlumbingJobAttributes {
  plumbingService: string
  issue: string
  symptoms: string
  frequency: string
}

export interface ElectricalJobAttributes {
  electricalService: string
  scope: string
  issue: string
}

export interface CleaningJobAttributes {
  cleaningType: string
  focusArea: string
  areaSize: string
  condition: string
}

export interface RenovationJobAttributes {
  floorArea: number
  propertyArea: string
  existingCondition: string 
  typeOfFinish: string
}

export interface ModularCabinetJobAttributes {
  cabinetType: string
  estimatedDimension: string
  material: string
}

export interface PestControlJobAttributes {
  propertyType: string
  floorArea: number
  pestType: string
  severity: string
}

export interface AirconJobAttributes {
  numberOfUnits: number
  serviceType: string
  airconType: string
  brand: string
}

export interface ConstructionJobAttributes {
  lotArea: number
  floorArea: number
  numberOfFloors: number
  typeOfFinish: string
  hasBlueprint: string
}

// TODO: Make enum values capitalized
export enum JobTimeline {
  asap = "asap",
  inWeeks = "in-weeks",
  inMonths = "in-months",
  inHalfYear = "in-half-year",
}

export interface JobAttributes {
  jobCategoryId: string
  cityId: string
  budgetMin: number | null
  budgetMax: number | null
  timeline: JobTimeline
  description: string
  customerId?: string
  fileList?: FileList
  categoryAttrsValues?: CategoryAttributesUnion
  jobType: JobType['jobType']
  shareContact: boolean
  phoneNumber: string | null
}

export interface UpdateJobAttributes {
  budgetMin?: number | null
  budgetMax?: number | null
  timeline?: JobTimeline
  description?: string
  status?: JobType['status']
  winningEstimateId?: string | null
  categoryAttrsValues?: CategoryAttributesUnion
  bumpedAt?: string
}

export default interface JobType {
  id: string
  uuid: string
  customer: CustomerType
  status: 'active' | 'archived' | 'auto_archived' | 'completed'
  estimates: EstimateType[]
  jobCategoryId: string
  jobCategory: JobCategoryType
  winningEstimate: EstimateType
  cityId: string
  city: CityType
  budgetMin?: number
  budgetMax?: number
  timeline: JobTimeline
  description: string
  bumpedAt: string
  createdAt: string
  images: ImageType[]
  summary: string
  estimatesCount: number
  categoryAttrsValues: Record<string, string>
  jobType: 'free' | 'promoted' | 'high_value'
  price: number
  shareContact: boolean
}
