//@ts-expect-error: This is needed by Rails
import React, { ChangeEvent, useContext } from 'react'
import { Button } from '@mui/joy'
import ImageIcon from '@mui/icons-material/Image'
import { LayoutContext } from '../layouts/LayoutContextProvider'

interface Props {
  setImagePreviews: (previews: string[]) => void
  onChange: (values: File[]) => void
}

export default function ImageUploadButton({ setImagePreviews, onChange }: Props) {
  const { showAlert } = useContext(LayoutContext)

  async function handleFilesChange(e: ChangeEvent<HTMLInputElement>) {
    const MAX_FILE_SIZE = 25 * 1024 * 1024
    const MAX_NUMBER_OF_FILES = 5
    const selectedFiles = Array.from(e.target.files || [])
    const errors: string[] = []
    const validFiles: File[] = []

    if (selectedFiles.length > MAX_NUMBER_OF_FILES) {
      errors.push(`You can only upload ${MAX_NUMBER_OF_FILES} images`)
    } else {
      selectedFiles.forEach(async (file) => {
        if (file.size > MAX_FILE_SIZE) {
          errors.push(`${file.name} exceeds the maximum size of 5MB`)
        } else {
          validFiles.push(file)
        }
      })
    }

    if (errors.length === 0) {
      onChange(validFiles)
      setImagePreviews(validFiles.map((file) => URL.createObjectURL(file)))
    } else {
      showAlert(errors.join("\n"), 'danger')
    }
  }

  return (
    <Button color="neutral" variant="outlined" startDecorator={<ImageIcon/>} component="label">
      Select images (max 5)
      <input
        style={{
          height: '1px',
          width: '1px',
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          overflow: 'hidden',
          position: 'absolute',
          bottom: 0,
          left: 0
        }}
        type="file"
        accept="image/*"
        multiple
        onChange={handleFilesChange}
      />
    </Button>
  )
}
