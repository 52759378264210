import { gql, TypedDocumentNode } from '@apollo/client';
import { AmbassadorCustomerType } from '../types/customer-type';
import { AdminBusinessType, AmbassadorBusinessType } from '../types/business-user-type';

export const VERIFY_BUSINESS_REFERRAL: TypedDocumentNode<{ verifyBusinessReferral: { errors: string[], message: string }}, { businessProfileId: string, referralCode: string }> = gql`
  mutation VerifyBusinessReferral($businessProfileId: ID!, $referralCode: String!) {
    verifyBusinessReferral(input: { businessProfileId: $businessProfileId, referralCode: $referralCode }) {
      errors
      message
    }
  }
`

export const GET_ADMIN_BUSINESSES: TypedDocumentNode<{ adminBusinesses: AdminBusinessType[] }, { q?: string }> = gql`
  query GetAdminBusinesses($q: String) {
    adminBusinesses(q: $q) {
      id
      name
      email
      referralCode
      completed
      coverageCompleted
      referralVerified
      coveredLocations
      coveredCategories
      createdAt
    }
  }
`

export const GET_AMBASSADOR_CUSTOMERS: TypedDocumentNode<{ ambassadorCustomers: AmbassadorCustomerType[] }, { q?: string }> = gql`
  query GetAmbassadorCustomers($q: String) {
    ambassadorCustomers(q: $q) {
      id
      fullName
      referralVerified
      createdAt
    }
  }
`

export const GET_AMBASSADOR_BUSINESSES: TypedDocumentNode<{ ambassadorBusinesses: AmbassadorBusinessType[] }, { q?: string }> = gql`
  query GetAmbassadorBusinesses($q: String) {
    ambassadorBusinesses(q: $q) {
      id
      name
      email
      referralVerified
      createdAt
    }
  }
`
