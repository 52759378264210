import { createContext, useContext, ReactNode, useMemo } from 'react'
import { createConsumer, Consumer } from '@rails/actioncable'
import { LayoutContext } from '../layouts/LayoutContextProvider';

// Define the type for the context
interface ActionCableContextType {
  cable: Consumer;
}

// Create the context
const ActionCableContext = createContext<ActionCableContextType | undefined>(undefined);

// Define the provider's props
interface ActionCableProviderProps {
  children: ReactNode;
}

export const ActionCableProvider: React.FC<ActionCableProviderProps> = ({ children }) => {
  const { environment } = useContext(LayoutContext)
  const webSocketUrl = environment === 'production' ? 'wss://app.homemaster.ph/cable' : 'ws://localhost:5000/cable'
  const cable = useMemo(() => createConsumer(webSocketUrl), []);

  return (
    <ActionCableContext.Provider value={{ cable }}>
      {children}
    </ActionCableContext.Provider>
  );
};

// Custom hook to use the ActionCable context
export const useCable = (): Consumer => {
  const context = useContext(ActionCableContext);
  if (!context) {
    throw new Error("useCable must be used within an ActionCableProvider");
  }
  return context.cable;
};
