import { Box } from '@mui/joy'

export default function PageNotFound() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 20, height: '100vh' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ fontSize: '50px' }}>Page Not Found</Box>
        <Box>This is not the page you are looking for</Box>
      </Box>
    </Box>
  )
}
