import { Box, Typography } from '@mui/joy'
import { GatesAndFencesJobAttributes } from '../../types/job-type'
import { colors } from '../../layouts/Theme'

export default function GatesAndFencesDetails({ attrs }: { attrs: GatesAndFencesJobAttributes }) {
  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Service Needed</Typography>
        <Typography level="title-sm">{attrs.serviceType}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Estimated Gate Width (meters)</Typography>
        <Typography level="title-sm">{attrs.gateWidth}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Estimated Gate Height (meters)</Typography>
        <Typography level="title-sm">{attrs.gateHeight}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Estimated Fence Width (meters)</Typography>
        <Typography level="title-sm">{attrs.fenceWidth}</Typography>
      </Box>
      <Box>
        <Typography level="title-sm" sx={{ mb: '5px', color: colors.gray3 }}>Estimated Fence Height (meters)</Typography>
        <Typography level="title-sm">{attrs.fenceHeight}</Typography>
      </Box>
    </Box>
  )
}
