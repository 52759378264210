import { useSuspenseQuery } from '@apollo/client'
import { Box, Grid, IconButton, Typography } from '@mui/joy'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_JOB_CATEGORY } from '../graphql/job-category-queries'
import JobForm from '../components/JobForm'
import { JobAttributes } from '../types/job-type'
import { useContext } from 'react'
import { LayoutContext } from '../layouts/LayoutContextProvider'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

export default function PublicNewJobPage() {
  const { categorySlug } = useParams()
  const navigate = useNavigate()
  const { data: { jobCategory }} = useSuspenseQuery(GET_JOB_CATEGORY, { variables: { slug: categorySlug! } })
  const { showAlert } = useContext(LayoutContext)

  function onSubmit(data: JobAttributes) {
    const { cityId, timeline, description } = data
    if (cityId && timeline && description) {
      const filesArray: Promise<{ name: string; type: string; dataUrl: string }>[] = Array.from(data.fileList || []).map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve({ name: file.name, type: file.type, dataUrl: reader.result as string });
          reader.onerror = reject;
          reader.readAsDataURL(file); // Convert file to base64
        });
      });

      Promise.all(filesArray).then((fileDataURLs) => {
        localStorage.setItem('hm:phoneNumber', data.phoneNumber || '')
        localStorage.setItem('hm:newJob', JSON.stringify({ ...data, jobCategoryId: jobCategory.id, fileDataURLs }))
        navigate('/get_estimates/login')
      })

    } else {
      showAlert("Please complete all the fields to continue", "danger")
    }
  }

  return (
    <Grid container justifyContent="center" sx={{ pt: 2, pb: 4, px: { xs: 3 }}}>
      <Grid xs={12} md={7}>
        <IconButton
          sx={{ pl: '5px', pr: '10px' }}
          onClick={() => navigate('/')}
        >
          <KeyboardArrowLeftIcon sx={{ mr: 0 }} />
          Back
        </IconButton>
      </Grid>
      <Grid xs={12} md={7}>
        <Box sx={{ p: '10px', pt: 0, mb: 1 }}>
          <Typography level="h4" sx={{ mt: 1 }}>Ilagay ang location at detalye</Typography>
          <Typography level="body-sm" sx={{ fontSize: '14px' }}>Ang mga information na ito ay kailangan upang makapag bigay ng presyo ang mga businesses</Typography>
        </Box>
        <JobForm newCustomer onSubmit={onSubmit} saving={false} submitLabel="Confirm" jobCategory={jobCategory} />
      </Grid>
    </Grid>
  )
}
