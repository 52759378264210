//@ts-expect-error: This is needed by Rails
import React from 'react'
import { Box } from '@mui/joy'
import { NumericFormat } from 'react-number-format'
import { colors } from '../layouts/Theme'
import PaymentsIcon from '@mui/icons-material/Payments'

export default function DisplayMinMax({ valueMin, valueMax, withBorder = true }: { valueMin: number | undefined, valueMax: number | undefined, withBorder?: boolean }) {
  if (!valueMin && !valueMax) { return }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', color: colors.gray3, border: withBorder ? `1px solid ${colors.gray1}` : '', borderRadius: '16px', px: '8px', height: '30px' }}>
      <PaymentsIcon sx={{ mr: '5px', color: colors.gray1 }}/>
      {(valueMin && valueMax) &&
        <>
          <NumericFormat thousandSeparator="," displayType="text" prefix="₱" value={valueMin} /> – <NumericFormat thousandSeparator="," displayType="text" prefix="₱" value={valueMax} />
        </>}

      {valueMin && !valueMax && <NumericFormat thousandSeparator="," displayType="text" prefix="₱" value={valueMin} />}
    </Box>
  )
}
